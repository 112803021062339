import Api from './api/apiGPC'
import { buildRequestParamsObj } from 'help/requests'

const baseUrl = '/usuario'
const resetPasswordHeaders = {
  'Content-Type': 'application/json'
}

const User = {
  buscarUsuarios(data) {
    return Api.request(`${baseUrl}/buscarUsuarios`, buildRequestParamsObj(data))
  },
  buscarUsuariosAtivos(data) {
    return Api.request(`${baseUrl}/buscarUsuariosAtivos`, buildRequestParamsObj(data))
  },
  buscarUsuariosGrid(data) {
    return Api.request(`${baseUrl}/buscarUsuariosGrid`, buildRequestParamsObj(data))
  },
  buscarPorId(data) {
    return Api.request(`${baseUrl}/buscarPorId`, buildRequestParamsObj(data))
  },
  changePassword(data) {
    return Api.request(`${baseUrl}/alterarSenha`, buildRequestParamsObj(data))
  },
  salvarAtualizarUsuario(data) {
    return Api.request(`${baseUrl}/salvarAtualizarUsuario`, buildRequestParamsObj(data))
  },
  exportarUsuarios(data) {
    return Api.request(`${baseUrl}/export`, buildRequestParamsObj(data))
  },
  // Ainda não implementados ---------------------
  buscarUsuarioLogadoMenu(data) {
    return Api.request(`${baseUrl}/buscarUsuarioLogadoMenu`, buildRequestParamsObj(data))
  },
  atualizarUltimoAcesso(data) {
    return Api.request(`${baseUrl}/atualizarUltimoAcesso`, buildRequestParamsObj(data))
  },
  buscarPermissoesMenu(data) {
    return Api.request(`${baseUrl}/buscarPermissoesMenu`, buildRequestParamsObj(data))
  },
  buscarPerfis(data) {
    return Api.request('perfil/buscarInput', buildRequestParamsObj(data))
  },
  removerUsuario(data) {
    return Api.request(`${baseUrl}/removerUsuario`, buildRequestParamsObj(data))
  },
  importarArquivo(data, idUsuario, params = {}) {
    const headers = { ...Api.getFileHeaders(), idUsuario }
    const method = 'POST'
    return Api.request(`${baseUrl}/importarArquivo`, { data, method, headers, params })
  },
  resetPassword(data) {
    return Api.request('autenticacao/resetarSenha', buildRequestParamsObj(data, resetPasswordHeaders))
  },
  getUserProfile(data) {
    return Api.request(`${baseUrl}/buscarUsuarioLogadoPerfil`, buildRequestParamsObj(data))
  }
}

export default User
