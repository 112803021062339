import React, { memo } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import { StyledButton, StyledTextButton } from './style'

function Button ({
  variant,
  color,
  fontColor,
  fontSize,
  fontWeight,
  startIcon,
  endIcon,
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  disabled,
  onBlur,
  href,
  backgroundColor,
  isLoading,
  type,
  fullWidth,
  isText,
  underlined,
  noPaddingLeft,
  justifyContent,
  noPadding,
  noFixedHeight,
  noBorder,
  id,
  dataTestId
}) {
  const buttonProps = {
    variant,
    fullWidth,
    startIcon: !isLoading ? startIcon : null,
    fontColor,
    fontSize,
    endIcon: !isLoading ? endIcon : null,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onFocus,
    onBlur,
    href,
    underlined,
    backgroundColor,
    disabled,
    type,
    fontWeight,
    noPaddingLeft,
    justifyContent,
    noPadding,
    noFixedHeight,
    noBorder,
    id,
    dataTestId
  }

  const getButtonContent = () => {
    if (!isLoading) {
      return children
    }
    return <CircularProgress size={20} color="inherit" />
  }

  if (isText) {
    return (
      <StyledTextButton
        {...buttonProps}
      >
        {getButtonContent()}
      </StyledTextButton>
    )
  }

  return (
    <StyledButton
      color={color}
      {...buttonProps}
    >
      {getButtonContent()}
    </StyledButton>
  )
}

Button.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  endIcon: PropTypes.node,
  fontColor: PropTypes.string,
  justifyContent: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  href: PropTypes.string,
  isLoading: PropTypes.bool,
  isText: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  startIcon: PropTypes.node,
  type: PropTypes.string,
  underlined: PropTypes.bool,
  variant: PropTypes.string,
  children: PropTypes.node.isRequired,
  noPaddingLeft: PropTypes.bool,
  noPadding: PropTypes.bool,
  noFixedHeight: PropTypes.bool,
  noBorder: PropTypes.bool,
  id: PropTypes.string,
  dataTestId: PropTypes.string
}

Button.defaultProps = {
  fullWidth: false,
  isLoading: false,
  isText: false,
  noPaddingLeft: false,
  noPadding: false,
  noFixedHeight: false,
  disabled: false,
  underlined: false,
  variant: 'contained',
  fontColor: '',
  fontSize: '',
  justifyContent: '',
  fontWeight: 400,
  backgroundColor: '',
  color: 'primary',
  endIcon: null,
  href: null,
  onClick: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  onFocus: () => {},
  onBlur: () => {},
  startIcon: null,
  type: 'button',
  noBorder: false,
  id: null,
  dataTestId: null
}

export default memo(Button)
