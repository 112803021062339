const invalidNumberChars = ['-', '+', 'e']

const onKeyDownNumber = (event) => {
  if (invalidNumberChars.includes(event.key)) {
    event.stopPropagation()
    event.preventDefault()
  }
}

const onPasteNumber = (event) => {
  const clipboardData = event.clipboardData || window.clipboardData
  const pastedData = clipboardData.getData('Text').toUpperCase()

  if (pastedData.indexOf('E') > -1) {
    event.stopPropagation()
    event.preventDefault()
  }
}

export {
  onKeyDownNumber,
  onPasteNumber
}
