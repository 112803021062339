import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const TradeAction = lazy(() => lazyRetry(() => import('containers/TradeAction')))
const TradeActionRegister = lazy(() => lazyRetry(() => import('containers/TradeAction/register')))

const route = [
  {
    name: i18n.t('tradeAction.title'),
    path: '/trade-action',
    main: TradeAction,
    exact: true,
    permissions: ['ROLE_CADASTRO_ACAO_TRADE_LEITURA']
  },
  {
    name: i18n.t('tradeAction.registerTitle'),
    path: '/trade-action/register',
    main: TradeActionRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_ACAO_TRADE_ESCRITA']
  },
  {
    name: i18n.t('tradeAction.editTitle'),
    path: '/trade-action/edit/:id',
    main: TradeActionRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_ACAO_TRADE_LEITURA']
  },
  {
    name: i18n.t('tradeAction.copyTitle'),
    path: '/trade-action/copy/:id',
    main: TradeActionRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_ACAO_TRADE_LEITURA']
  }
]

export default route
