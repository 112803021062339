/* eslint-disable max-lines */
import React from 'react'
import i18n from 'i18next'
import AdminIcon from '@material-ui/icons/VpnKey'
import ReceiptIcon from '@material-ui/icons/Receipt'
import DescriptionIcon from '@material-ui/icons/Description'
import AssessmentIcon from '@material-ui/icons/Assessment'
import { ReactComponent as Registers } from 'assets/icons/ic-clientandprocucts.svg'
import { ReactComponent as Support } from 'assets/icons/ic-support.svg'
import _orderBy from 'lodash/orderBy'

const primaryMenus = {
  ADMIN: 'admin',
  REGISTERS: 'registers',
  CONTRACT_AND_ACTIONS: 'contract and actions',
  EDOCS: 'edocs',
  REPORTS: 'reports',
  HISTORICAL_BASES: 'historicalBases',
  SUPPORT: 'support'
}

const openModalChangePassword = () => {
  window.open('https://drive.google.com/drive/folders/1PiRadDt-vmnjZu9gqvSZPsYrRQ14nP_c', '_blank')
}

export const getRoutes = (closeMenu, openChangePasswordModal) => [
  {
    icon: <AdminIcon />,
    title: i18n.t('menu.administration.title'),
    menuItem: primaryMenus.ADMIN,
    subItems: [
      {
        path: '/user',
        title: i18n.t('menu.administration.user'),
        closeMenu,
        subItems: []
      },
      {
        path: '/profile',
        title: i18n.t('menu.administration.profile'),
        closeMenu,
        subItems: []
      }
    ]
  },
  {
    icon: <Registers fill='rgba(0, 0, 0, 0.54)' />,
    title: i18n.t('menu.registers.title'),
    menuItem: primaryMenus.REGISTERS,
    subItems: _orderBy([
      {
        path: '/economic-group',
        title: i18n.t('menu.registers.economicGroup'),
        closeMenu,
        subItems: []
      },
      {
        path: '/region',
        title: i18n.t('menu.registers.region'),
        closeMenu,
        subItems: []
      },
      {
        path: '/sectional',
        title: i18n.t('menu.registers.regional'),
        closeMenu,
        subItems: []
      },
      {
        path: '/distributor',
        title: i18n.t('menu.registers.distributor'),
        closeMenu,
        subItems: []
      },
      {
        path: '/potential',
        title: i18n.t('menu.registers.potential'),
        closeMenu,
        subItems: []
      },
      {
        path: '/business-type',
        title: i18n.t('menu.registers.businessType'),
        closeMenu,
        subItems: []
      },
      {
        path: '/segment',
        title: i18n.t('menu.registers.segment'),
        closeMenu,
        subItems: []
      },
      {
        path: '/channel',
        title: i18n.t('menu.registers.channel'),
        closeMenu,
        subItems: []
      },
      {
        path: '/district',
        title: i18n.t('menu.registers.district'),
        closeMenu,
        subItems: []
      },
      {
        path: '/customer',
        title: i18n.t('menu.registers.customer'),
        closeMenu,
        subItems: []
      },
      {
        path: '/category',
        title: i18n.t('menu.registers.category'),
        closeMenu,
        subItems: []
      },
      {
        path: '/product',
        title: i18n.t('menu.registers.product'),
        closeMenu,
        subItems: []
      },
      {
        path: '/price-table',
        title: i18n.t('menu.registers.priceTable'),
        closeMenu,
        subItems: []
      },
      {
        path: '/responsible-channel',
        title: i18n.t('menu.registers.responsibleChannel'),
        closeMenu,
        subItems: []
      },
      {
        path: '/charge',
        title: i18n.t('menu.registers.charge'),
        closeMenu,
        subItems: []
      },
      {
        path: '/approval-group',
        title: i18n.t('menu.registers.approvalGroup'),
        closeMenu,
        subItems: []
      },
      {
        path: '/approval-flow',
        title: i18n.t('menu.registers.approvalFlow'),
        closeMenu,
        subItems: []
      },
      {
        path: '/sku-code',
        title: i18n.t('menu.registers.skuCode'),
        closeMenu,
        subItems: []
      },
      {
        path: '/group-x-distributor',
        title: i18n.t('menu.registers.groupXDistributor'),
        closeMenu,
        subItems: []
      }
    ], 'title', 'asc')
  },
  {
    icon: <ReceiptIcon fill='rgba(0, 0, 0, 0.54)' />,
    title: i18n.t('menu.contractAndActions.title'),
    menuItem: primaryMenus.CONTRACT_AND_ACTIONS,
    subItems: _orderBy([
      {
        path: '/trade-action',
        title: i18n.t('menu.contractAndActions.tradeAction'),
        closeMenu,
        subItems: []
      },
      {
        path: '/policy-difference',
        title: i18n.t('menu.contractAndActions.policyDifference'),
        closeMenu,
        subItems: []
      },
      {
        path: '/punctual-bonus',
        title: i18n.t('menu.contractAndActions.punctualBonus'),
        closeMenu,
        subItems: []
      },
      {
        path: '/no-nf-action',
        title: i18n.t('menu.contractAndActions.noNfAction'),
        closeMenu,
        subItems: []
      },
      {
        path: '/trousseau-bonus-ka',
        title: i18n.t('menu.contractAndActions.trousseauBonusKa'),
        closeMenu,
        subItems: []
      },
      {
        path: '/customer-specific',
        title: i18n.t('menu.contractAndActions.customerSpecific'),
        closeMenu,
        subItems: []
      },
      {
        path: '/parish-route',
        title: i18n.t('menu.contractAndActions.parishRoute'),
        closeMenu,
        subItems: []
      }
    ], 'title', 'asc')
  },
  {
    icon: <DescriptionIcon fill='rgba(0, 0, 0, 0.54)' />,
    title: i18n.t('menu.edocs.title'),
    menuItem: primaryMenus.EDOCS,
    subItems: _orderBy([
      {
        title: i18n.t('menu.edocs.edocsDetailed'),
        closeMenu,
        subItems: [
          {
            title: i18n.t('menu.edocs.edocsFiscalNotes'),
            closeMenu,
            path: '/edocs-fiscal-notes',
            subItems: []
          },
          {
            title: i18n.t('menu.edocs.edocsCampaigns'),
            closeMenu,
            path: '/edocs-campaigns',
            subItems: []
          },
          {
            title: i18n.t('menu.edocs.edocsRefunds'),
            closeMenu,
            subItems: [
              {
                path: '/edocs-refunds-manual-adjustments',
                title: i18n.t('menu.edocs.edocsRefundsManualAdjustments'),
                closeMenu,
                subItems: []
              },
              {
                path: '/edocs-refunds-fiscal-notes',
                title: i18n.t('menu.edocs.edocsRefundsFiscalNotes'),
                closeMenu,
                subItems: []
              },
              {
                path: '/edocs-refunds-refund-requests',
                title: i18n.t('menu.edocs.edocsRefundRequests'),
                closeMenu,
                subItems: []
              }
            ]
          }
        ]
      },
      {
        path: '/request-refund',
        title: i18n.t('menu.edocs.requestRefund'),
        closeMenu,
        subItems: []
      },
      {
        path: '/manual-adjustment',
        title: i18n.t('menu.edocs.manualAdjustment'),
        closeMenu,
        subItems: []
      },
      {
        path: '/closure',
        title: i18n.t('menu.edocs.closure'),
        closeMenu,
        subItems: []
      },
      {
        path: '/customer-refund',
        title: i18n.t('menu.edocs.customerRefund'),
        closeMenu,
        subItems: []
      },
      {
        path: '/campaign-refund',
        title: i18n.t('menu.edocs.campaignRefund'),
        closeMenu,
        subItems: []
      },
      {
        path: '/nfes-importer',
        title: i18n.t('menu.edocs.nfesImporter'),
        closeMenu,
        subItems: []
      },
      {
        path: '/change-expiration-date',
        title: i18n.t('menu.edocs.changeExpirationDate'),
        closeMenu,
        subItems: []
      },
      {
        path: '/reprocess-nfes',
        title: i18n.t('menu.edocs.reprocessNFEs'),
        closeMenu,
        subItems: []
      }
    ], 'title', 'asc')
  },
  {
    icon: <AssessmentIcon fill='rgba(0, 0, 0, 0.54)' />,
    title: i18n.t('menu.reports.title'),
    menuItem: primaryMenus.REPORTS,
    subItems: _orderBy([
      {
        path: '/reports-contestation',
        title: i18n.t('menu.reports.contestation'),
        closeMenu,
        subItems: []
      },
      {
        path: '/reports-invoicing',
        title: i18n.t('menu.reports.invoicing'),
        closeMenu,
        subItems: []
      },
      {
        path: '/reports-unprocessed-notes',
        title: i18n.t('menu.reports.unprocessedNotes'),
        closeMenu,
        subItems: []
      },
      {
        path: '/reports-not-refunded',
        title: i18n.t('menu.reports.notRefunded'),
        closeMenu,
        subItems: []
      },
      {
        path: '/reports-customer-import',
        title: i18n.t('menu.reports.customerImport'),
        closeMenu,
        subItems: []
      },
      {
        path: '/reports-refund',
        title: i18n.t('menu.reports.refund'),
        closeMenu,
        subItems: []
      }
    ], 'title', 'asc')
  },
  {
    icon: <Support fill='rgba(0, 0, 0, 0.54)' />,
    title: i18n.t('menu.support.title'),
    closeMenu,
    menuItem: primaryMenus.SUPPORT,
    subItems: [
      {
        id: 'changeMyPassword',
        title: i18n.t('menu.support.changeMyPassword'),
        closeMenu,
        onClick: openChangePasswordModal,
        permitAll: true,
        subItems: []
      },
      {
        id: 'supportFiles',
        title: i18n.t('menu.support.supportFiles'),
        closeMenu,
        onClick: openModalChangePassword,
        permitAll: true,
        subItems: []
      }
    ]
  }
]