import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Form } from 'formik'
import Grid from '@material-ui/core/Grid'
import Input from 'components/Form/Input'
import Button from 'components/Button'

function ChangePasswordForm ({ handleSubmit, isSubmitting, handleToggleModal }) {
  const { t } = useTranslation()

  const commonInputProps = {
    fullWidth: true,
    type: 'password',
    variant: 'outlined',
    hasPasswordViewer: true,
    inputProps: { maxLength: 18 },
    disabled: isSubmitting
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item>
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              <Input
                name="oldPassword"
                id="oldPassword"
                label={t('general.oldPassword')}
                {...commonInputProps}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Input
                name="newPassword"
                id="newPassword"
                label={t('general.newPassword')}
                {...commonInputProps}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Input
                name="newPasswordConfirmation"
                id="newPasswordConfirmation"
                label={t('general.newPasswordConfirmation')}
                {...commonInputProps}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                type="submit"
                disabled={isSubmitting}
                isLoading={isSubmitting}
              >
                {t('general.actions.save')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                isText
                underlined
                variant="contained"
                fontColor="#212529"
                disabled={isSubmitting}
                onClick={() => { handleToggleModal(false) }}
              >
                {t('general.actions.cancel')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>)
}

ChangePasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleToggleModal: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired
}

export default ChangePasswordForm
