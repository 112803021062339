import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const SkuCode = lazy(() => lazyRetry(() => import('containers/SkuCode')))
const SkuCodeRegister = lazy(() => lazyRetry(() => import('containers/SkuCode/register')))

const route = [
  {
    name: i18n.t('sku.title'),
    path: '/sku-code',
    main: SkuCode,
    exact: true,
    permissions: ['ROLE_CADASTRO_CODIGO_SKU_LEITURA']
  },
  {
    name: i18n.t('sku.registerTitle'),
    path: '/sku-code/register',
    main: SkuCodeRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_CODIGO_SKU_ESCRITA']
  },
  {
    name: i18n.t('sku.editTitle'),
    path: '/sku-code/edit/:id',
    main: SkuCodeRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_CODIGO_SKU_ESCRITA']
  }
]

export default route
