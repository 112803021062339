import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const PolicyDifference = lazy(() => lazyRetry(() => import('containers/PolicyDifference')))
const PolicyDifferenceRegister = lazy(() => lazyRetry(() => import('containers/PolicyDifference/register')))

const route = [
  {
    name: i18n.t('policyDifference.title'),
    path: '/policy-difference',
    main: PolicyDifference,
    exact: true,
    permissions: ['ROLE_CADASTRO_DIFERENCA_POLITICA_LEITURA']
  },
  {
    name: i18n.t('policyDifference.registerTitle'),
    path: '/policy-difference/register',
    main: PolicyDifferenceRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_DIFERENCA_POLITICA_ESCRITA']
  },
  {
    name: i18n.t('policyDifference.editTitle'),
    path: '/policy-difference/edit/:id',
    main: PolicyDifferenceRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_DIFERENCA_POLITICA_LEITURA']
  },
  {
    name: i18n.t('policyDifference.copyTitle'),
    path: '/policy-difference/copy/:id',
    main: PolicyDifferenceRegister,
    exact: true
    // permissions: ['ROLE_CADASTRO_DIFERENCA_POLITICA_ESCRITA']
  }
]

export default route
