import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Distributor = lazy(() => lazyRetry(() => import('containers/Distributor')))
const DistributorRegister = lazy(() => lazyRetry(() => import('containers/Distributor/register')))

const route = [
  {
    name: i18n.t('distributor.title'),
    path: '/distributor',
    main: Distributor,
    exact: true,
    permissions: ['ROLE_CADASTRO_DISTRIBUIDOR_LEITURA']
  },
  {
    name: i18n.t('distributor.registerTitle'),
    path: '/distributor/register',
    main: DistributorRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_DISTRIBUIDOR_ESCRITA']
  },
  {
    name: i18n.t('distributor.editTitle'),
    path: '/distributor/edit/:id',
    main: DistributorRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_DISTRIBUIDOR_ESCRITA']
  }
]

export default route
