import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { FormControl as MuiFormControl, FormHelperText } from '@material-ui/core'
import { getFieldProps, areEqual } from '../Field/utils'
// import AlertHelperText from '../AlertHelperText'

// const renderAlertText = (value, helperText) => {
//   const showAlert = !helperText

//   return showAlert && (
//     <AlertHelperText value={value} />
//   )
// }

function FormControl ({ customComponent: Input, ...props }) {
  const {
    id,
    label,
    helperText,
    helperTextId,
    fullWidth,
    required,
    error,
    margin,
    ...fieldProps
  } = getFieldProps(props)
  return (
    <MuiFormControl error={error} fullWidth={fullWidth} required={required}>
      <Input {...fieldProps} error={error} {...props} id={id} aria-describedby={helperTextId} />
      {helperText && (
        <FormHelperText id={helperTextId}>
          {helperText}
        </FormHelperText>
      )}
      {/* {renderAlertText(fieldProps.value, helperText)} */}
    </MuiFormControl>
  )
}

FormControl.defaultProps = {
  margin: 'normal',
  required: false
}

FormControl.propTypes = {
  customComponent: PropTypes.elementType.isRequired,
  margin: PropTypes.string,
  required: PropTypes.bool
}

export default memo(FormControl, areEqual)
