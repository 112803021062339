import feature from 'common/utils/feature'
import _cloneDeep from 'lodash/cloneDeep'
import { hasAnyPermission } from 'help/jwt'

export const filterRoutes = (routes, seller) => {
  if (!feature.sellOnline) {
    return routes.filter(route => !!route.sellOnline === feature.sellOnline)
  }

  if (seller && !seller.finished_onboarding) {
    return routes.filter(route => route.path !== '/minha-conta/informacoes-bancarias')
  }

  return routes
}

const createNewRoutes = (routes = [], permissions = {}, token) => {
  const newRoutes = []

  routes.forEach((route) => {
    if (!route.path || permissions[route.path]) {
      if (Array.isArray(route.subItems)) {
        route.subItems = createNewRoutes(route.subItems, permissions, token)
      }

      if (route.path || route.permitAll || (Array.isArray(route.subItems) && route.subItems.length > 0) || (Array.isArray(route.permissions) && hasAnyPermission(token, route.permissions))) {
        newRoutes.push(route)
      }
    }
  })

  return newRoutes
}

export const routesToPermissions = (routes = [], token) => {
  const permissions = {}
  if (!Array.isArray(routes)) {
    return {}
  }

  routes.forEach((route) => {
    if (Array.isArray(route.permissions)) {
      permissions[route.path] = hasAnyPermission(token, route.permissions)
    } else {
      permissions[route.path] = true
    }
  })

  return permissions
}

export const filterRoutesByPermissions = (routes = [], routesPermissions = [], token) => {
  const permissions = routesToPermissions(routesPermissions, token)
  return createNewRoutes(_cloneDeep(routes), permissions, token)
}

