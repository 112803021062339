import React from 'react'
import { useInterval } from 'react-use'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { GlobalStyle } from './styled'
import { healthCheck, doubleCheck } from './help/requests'

function ErrorPage() {
  const { t } = useTranslation()

  const location = useLocation()

  location.state = {
    fullWidth: true
  }

  const params = new URLSearchParams(location.search)

  useInterval(() => {
    healthCheck().then((resp) => {
      const data = resp ? resp.data : null
      if (data && data.status === 'UP') {
        window.location.href = atob(params.get('backTo'))
      }
    }).catch(() => {
      doubleCheck().then(() => {
        window.location.href = atob(params.get('backTo'))
      })
    })
  }, 30000)

  document.head.insertAdjacentHTML(
    'beforeend',
    '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" integrity="sha512-iBBXm8fW90+nuLcSKlbmrPcLa0OT92xO1BIsZ+ywDWZCvqsWgccV3gFoRBv0z+8dLJgyAHIhR35VZc2oM/gI1w==" crossorigin="anonymous" referrerpolicy="no-referrer" />'
  )

  return (
    <>
      <GlobalStyle />
      <section id="errorContainer">
        <div className="card">
          <div className="card-header">
            <i className="fas fa-cloud"></i>
            <i className="fas fa-tools"></i>
          </div>
          <div className="card-body">
            <h1 className="title"> {t('errorPage.title')} </h1>
            <p className="text"> {t('errorPage.text')} </p>
            <p className="text luizalabs"> {t('errorPage.endText')} </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default ErrorPage
