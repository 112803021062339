import React, { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import SingleItem from './SingleItem'
import {
  StyledSubList,
  StyledListItem,
  StyledListItemText
} from './styles'

function ExpandbleItem ({
  icon,
  title,
  selected,
  subItems,
  menuOpened
}) {
  const [showSubList, setShowSubList] = useState(false)

  // @TODO arrumar problema com mouseOver e mouseOut no menu
  useEffect(() => {
    if (!menuOpened) {
      setShowSubList(false)
    }
  }, [menuOpened])

  return (
    <>
      <StyledListItem
        button
        dataTestId={title}
        selected={selected}
        onClick={() => setShowSubList(!showSubList)}
      >
        {icon && <ListItemIcon>
          {icon}
        </ListItemIcon>}
        <StyledListItemText primary={title} rootLevel />
        {showSubList ? <ExpandLess /> : <ExpandMore />}
      </StyledListItem>
      <Collapse in={showSubList} timeout="auto" unmountOnExit>
        <StyledSubList component="div" disablePadding>
          {subItems.map((subItem, index) => {
            const { subItems, closeMenu } = subItem
            if (subItems.length > 0) {
              return <ExpandbleItem key={index} {...subItem} menuOpened={menuOpened} />
            }
            return <SingleItem
              {...subItem}
              isSubitem
              key={index}
              closeMenu={closeMenu}
            />
          })}
        </StyledSubList>
      </Collapse>
    </>
  )
}

ExpandbleItem.propTypes = {
  icon: PropTypes.element,
  selected: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subItems: PropTypes.array.isRequired,
  menuOpened: PropTypes.bool.isRequired
}

ExpandbleItem.defaultProps = {
  icon: null,
  selected: false
}

export default memo(ExpandbleItem)
