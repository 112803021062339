/* eslint-disable no-else-return */
const browserNames = {
  CHROME_NAME: 'Chrome',
  EDG_NAME: 'MS Edge Chromium',
  EDGE_NAME: 'MS Edge (EdgeHtml)',
  OPERA_NAME: 'Opera',
  IE_NAME: 'Internet Explorer',
  MOZILLA_NAME: 'Mozilla Firefox',
  SAFARI_NAME: 'Safari',
  OTHER_BROWSER_NAME: 'Other'
}

const browsersVersionValid = [
  {
    name: browserNames.CHROME_NAME,
    version: '80.0.3987.42',
    releaseDate: '2020-01-08',
    urlReference: 'https://bit.ly/3u7kIjo'
  }
]

const getVersion = (fullAgentString, sinceIndex) => {
  const agentString = fullAgentString.substr(sinceIndex)
  const indexAfterAgent = agentString.indexOf('/') + 1
  return agentString.substr(indexAfterAgent).match(/(?:\.|[0-9])*/)[0]
}

const getValidVersion = (browserName, browserVersion) => (
  browsersVersionValid.find(item => (
    browserName === item.name &&
    Number(browserVersion.match(/([0-9])*/)[0], 10) >= Number(item.version.match(/([0-9])*/)[0], 10)
  ))
)

const getDetectBrowser = (windowBrowser) => {
  const browser = {
    name: browserNames.OTHER_BROWSER_NAME,
    version: 'no version'
  }
  const agent = (
    windowBrowser &&
    windowBrowser.navigator &&
    windowBrowser.navigator.userAgent &&
    windowBrowser.navigator.userAgent.toLowerCase()
  )
  if (agent && agent.length) {
    if (agent.indexOf('edge') > -1) {
      browser.version = getVersion(agent, agent.indexOf('edge'))
      browser.name = browserNames.EDGE_NAME
      return browser
    } else if (agent.indexOf('edg') > -1) {
      browser.version = getVersion(agent, agent.indexOf('edg'))
      browser.name = browserNames.EDG_NAME
      return browser
    } else if (agent.indexOf('opr') > -1 && !!windowBrowser.opr) {
      browser.version = getVersion(agent, agent.indexOf('opr'))
      browser.name = browserNames.OPERA_NAME
      return browser
    } else if (agent.indexOf('chrome') > -1 && !!windowBrowser.chrome) {
      browser.version = getVersion(agent, agent.indexOf('chrome'))
      browser.name = browserNames.CHROME_NAME
      return browser
    } else if (agent.indexOf('trident') > -1) {
      browser.version = getVersion(agent, agent.indexOf('trident'))
      browser.name = browserNames.IE_NAME
      return browser
    } else if (agent.indexOf('firefox') > -1) {
      browser.version = getVersion(agent, agent.indexOf('firefox'))
      browser.name = browserNames.MOZILLA_NAME
      return browser
    } else if (agent.indexOf('safari') > -1) {
      browser.version = getVersion(agent, agent.indexOf('safari'))
      browser.name = browserNames.SAFARI_NAME
      return browser
    }
  }

  return browser
}

const isTestingMode = () => (new URLSearchParams(window.location.search).get('testingMode') !== null)

const isValidBrowser = (windowBrowser, browserName, validMinVersion) => {
  if (!isTestingMode()) {
    const detectedBrowser = getDetectBrowser(windowBrowser)
    return (detectedBrowser.name === browserName) &&
        (validMinVersion ? getValidVersion(detectedBrowser.name, detectedBrowser.version) : true)
  }
  return true
}

const getDetectBrowserHelper = windowBrowser => ({
  getDetectBrowser: () => getDetectBrowser(windowBrowser),
  isValidBrowser: (browserName, validMinVersion = false) => isValidBrowser(windowBrowser, browserName, validMinVersion)
})

export {
  browserNames,
  browsersVersionValid,
  getDetectBrowserHelper
}
