import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const User = lazy(() => lazyRetry(() => import('containers/User')))
const UserRegister = lazy(() => lazyRetry(() => import('containers/User/register')))

const route = [
  {
    name: i18n.t('routes.user.index'),
    path: '/user',
    main: User,
    exact: true,
    permissions: ['ROLE_CADASTRO_USUARIO']
  },
  {
    name: i18n.t('routes.user.register'),
    path: '/user/register',
    main: UserRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_USUARIO']
  },
  {
    name: i18n.t('routes.user.edit'),
    path: '/user/edit/:id',
    main: UserRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_USUARIO']
  }
]

export default route
