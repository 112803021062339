import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const ApprovalGroup = lazy(() => lazyRetry(() => import('containers/ApprovalGroup')))
const ApprovalGroupRegister = lazy(() => lazyRetry(() => import('containers/ApprovalGroup/register')))

const route = [
  {
    name: i18n.t('approvalGroup.title'),
    path: '/approval-group',
    main: ApprovalGroup,
    exact: true,
    permissions: ['ROLE_CADASTRO_GRUPO_APROVACAO_LEITURA']
  },
  {
    name: i18n.t('approvalGroup.registerTitle'),
    path: '/approval-group/register',
    main: ApprovalGroupRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_GRUPO_APROVACAO_ESCRITA']
  },
  {
    name: i18n.t('approvalGroup.editTitle'),
    path: '/approval-group/edit/:id',
    main: ApprovalGroupRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_GRUPO_APROVACAO_ESCRITA']
  }
]

export default route
