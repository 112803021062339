import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const PriceTable = lazy(() => lazyRetry(() => import('containers/PriceTable')))
const PriceTableRegister = lazy(() => lazyRetry(() => import('containers/PriceTable/register')))

const route = [
  {
    name: i18n.t('priceTable.title'),
    path: '/price-table',
    main: PriceTable,
    exact: true,
    permissions: ['ROLE_CADASTRO_TABELA_PRECO_LEITURA']
  },
  {
    name: i18n.t('priceTable.registerTitle'),
    path: '/price-table/register',
    main: PriceTableRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_TABELA_PRECO_ESCRITA']
  },
  {
    name: i18n.t('priceTable.editTitle'),
    path: '/price-table/edit/:id',
    main: PriceTableRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_TABELA_PRECO_ESCRITA']
  }
]

export default route
