import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const CustomerSpecific = lazy(() => lazyRetry(() => import('containers/CustomerSpecific')))
const CustomerSpecificRegister = lazy(() => lazyRetry(() => import('containers/CustomerSpecific/register')))

const route = [
  {
    name: i18n.t('customerSpecific.title'),
    path: '/customer-specific',
    main: CustomerSpecific,
    exact: true,
    permissions: ['ROLE_CADASTRO_CUSTOMER_SPECIFIC_LEITURA']
  },
  {
    name: i18n.t('customerSpecific.registerTitle'),
    path: '/customer-specific/register',
    main: CustomerSpecificRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_CUSTOMER_SPECIFIC_ESCRITA']
  },
  {
    name: i18n.t('customerSpecific.editTitle'),
    path: '/customer-specific/edit/:id',
    main: CustomerSpecificRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_CUSTOMER_SPECIFIC_LEITURA']
  },
  {
    name: i18n.t('customerSpecific.copyTitle'),
    path: '/customer-specific/copy/:id',
    main: CustomerSpecificRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_CUSTOMER_SPECIFIC_ESCRITA']
  }
]

export default route
