import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { withField } from '../Field'
import { onKeyDownNumber, onPasteNumber } from '../utils'
import { StyledInput, StyledInputWrap, StyledVisilityButton } from './styled'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

const getInputProps = (type, inputProps) => {
  if (type === 'number') {
    return {
      ...inputProps,
      onPaste: onPasteNumber,
      onKeyDown: onKeyDownNumber
    }
  }

  return inputProps
}

function Input (props) {
  const { inputProps, fnValidData, onChange, hasPasswordViewer, type } = props
  const [isVisibilityOn, setIsVisibilityOn] = useState(false)

  const handleChange = (evt) => {
    if (fnValidData(evt.target.value)) {
      onChange(evt)
    }
  }
  return (
    <StyledInputWrap>
      <StyledInput
        className="inputForm"
        {...props}
        type={hasPasswordViewer && isVisibilityOn ? 'text' : type}
        inputProps={getInputProps(hasPasswordViewer && isVisibilityOn ? 'text' : type, inputProps)}
        onChange={handleChange}
      />
      {
        hasPasswordViewer && type === 'password' ? (
          <StyledVisilityButton
            type='button'
            onClick={() => (setIsVisibilityOn(!isVisibilityOn))}
          >
            { !isVisibilityOn ?
              (<VisibilityOffIcon className='visibility-icon' />) :
              (<VisibilityIcon className='visibility-icon' />)
            }
          </StyledVisilityButton>
        ) : null
      }
    </StyledInputWrap>
  )
}

Input.propTypes = {
  type: PropTypes.string,
  inputProps: PropTypes.object,
  noMarginTop: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  fnValidData: PropTypes.func,
  dataTestId: PropTypes.string,
  hasPasswordViewer: PropTypes.bool
}

Input.defaultProps = {
  type: 'text',
  inputProps: {},
  noMarginTop: false,
  fnValidData: () => true,
  dataTestId: null,
  hasPasswordViewer: false
}

export { Input }
export default memo(withField(Input))
