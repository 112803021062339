import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const EconomicGroup = lazy(() => lazyRetry(() => import('containers/EconomicGroup')))
const EconomicGroupRegister = lazy(() => lazyRetry(() => import('containers/EconomicGroup/register')))

const route = [
  {
    name: i18n.t('economicGroup.title'),
    path: '/economic-group',
    main: EconomicGroup,
    exact: true,
    permissions: ['ROLE_CADASTRO_GRUPO_ECONOMICO_LEITURA']
  },
  {
    name: i18n.t('economicGroup.registerTitle'),
    path: '/economic-group/register',
    main: EconomicGroupRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_GRUPO_ECONOMICO_ESCRITA']
  },
  {
    name: i18n.t('economicGroup.editTitle'),
    path: '/economic-group/edit/:id',
    main: EconomicGroupRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_GRUPO_ECONOMICO_ESCRITA']
  }
]

export default route
