import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const ApprovalFlow = lazy(() => lazyRetry(() => import('containers/ApprovalFlow')))
const ApprovalFlowRegister = lazy(() => lazyRetry(() => import('containers/ApprovalFlow/register')))

const route = [
  {
    name: i18n.t('approvalFlow.title'),
    path: '/approval-flow',
    main: ApprovalFlow,
    exact: true,
    permissions: ['ROLE_CADASTRO_FLUXO_APROVACAO_LEITURA']
  },
  {
    name: i18n.t('approvalFlow.registerTitle'),
    path: '/approval-flow/register',
    main: ApprovalFlowRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_FLUXO_APROVACAO_ESCRITA']
  },
  {
    name: i18n.t('approvalFlow.editTitle'),
    path: '/approval-flow/edit/:id',
    main: ApprovalFlowRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_FLUXO_APROVACAO_ESCRITA']
  }
]

export default route
