import React from 'react'
import TextField from '@material-ui/core/TextField'
import styled, { css } from 'styled-components'

function checkIfFilled (value) {
  return value !== null && value !== undefined && value !== ''
}

export const StyledVisilityButton = styled.button`
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 40px;
  height: 40px;
  padding: 0px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  .visibility-icon {
    color: #9e9e9e;
    font-size: 22px;
  }
`

export const StyledInputWrap = styled.div`
  position: relative;
`

export const StyledInput = styled(({ setFieldValue, fnValidData, format, noMarginTop, field, form, inputSize, isNumber, ...props }) => <TextField {...props} />)`
  color: #555a5f !important;
  border-radius: 4px;
  margin-bottom: 0px;
  ${({ noMarginTop }) => noMarginTop && 'margin-top: 0;'}
  ${({ inputSize }) => inputSize && css`width: ${inputSize}`}
  ${({ isNumber }) => isNumber && css`
    input {
      text-align: right;
    }
  `}

  .MuiInputBase-root { 
    overflow: hidden;
    input {
      ${({ multiline }) => (!multiline ? 'padding: 10.5px 14px;' : 'padding: 10px;')}
      ${({ hasPasswordViewer, type }) => (hasPasswordViewer && type === 'password' ? 'padding: 10.5px 40px 10.5px 14px;' : '')}
    }
  }
  
  label { z-index:101; }

  .Mui-disabled input {
    background-color: #D7D7D7;
    cursor: not-allowed;
  }

  .Mui-disabled textarea {
    z-index: 1;
  }

  .MuiOutlinedInput-input {
    padding: 0px;
  }

  .MuiFormLabel-root { 
    ${({ multiline }) => (!multiline ? `top: 50%;
        transform: translate(14px, -50%);` : 'transform: translate(14px, 14px);')}
    transition: all 0.3s ease;
  }
  .MuiFormLabel-root {
    ${({ value }) => (checkIfFilled(value) > 0 ? `
      top: 0;
      transform: translate(14px, -6px) scale(0.75);
      color: #333;
      background-color: white;
    ` : '')}
    ${({ value, disabled }) => ((checkIfFilled(value) && disabled) ? css`
      background: linear-gradient(to bottom, #fff 45%, #D7D7D7 55%);
      color: #555a5f;
    ` : '')}
  }
  .MuiFormLabel-root.Mui-focused { 
    top: 0;
    transform: translate(14px, -6px) scale(0.75);
    ${({ error }) => (error ? 'color: #eb0000;' : 'color: #333;')}
  }
  .MuiOutlinedInput-root fieldset{
    border-color: #555a5f;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    ${({ error }) => (error ? 'border-color: #eb0000;' : 'border-color: #333;')}
  }
  && input {
    z-index: 100;
  }
`
