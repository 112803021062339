import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Product = lazy(() => lazyRetry(() => import('containers/Product')))
const ProductRegister = lazy(() => lazyRetry(() => import('containers/Product/register')))

const route = [
  {
    name: i18n.t('product.title'),
    path: '/product',
    main: Product,
    exact: true,
    permissions: ['ROLE_CADASTRO_PRODUTO_LEITURA']
  },
  {
    name: i18n.t('product.registerTitle'),
    path: '/product/register',
    main: ProductRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_PRODUTO_ESCRITA']
  },
  {
    name: i18n.t('product.editTitle'),
    path: '/product/edit/:id',
    main: ProductRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_PRODUTO_ESCRITA']
  }
]

export default route
