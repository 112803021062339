import { getIn } from 'formik'
import _get from 'lodash/get'
import i18n from 'common/i18n'
import { isShallowEqual } from 'common/utils/list'

const getHelperId = (text, id) => (text && id ? `${id}-helper-text` : undefined)

export const getRootName = fieldName => _get(fieldName.split('['), 0, '')

const getFieldProps = (props) => {
  const {
    field,
    form,
    ...otherProps
  } = props
  const {
    touched,
    errors
  } = form
  const name = getRootName(field.name)
  const internalError = getIn(touched, field.name) && getIn(errors, field.name)
  const rootError = getIn(touched, name) && getIn(errors, name)
  const error = typeof rootError === 'string' ? rootError : internalError
  const hasError = !!error
  let helperText = null
  if (hasError) {
    helperText = (typeof error === 'string' ? i18n.t(error) : i18n.t(error[Object.keys(error)[0]]))
  }
  const helperTextId = getHelperId(helperText, otherProps.id)

  return {
    ...field,
    ...otherProps,
    helperText,
    helperTextId,
    error: hasError
  }
}

const getSwitchProps = ({
  field,
  form,
  ...props
}) => ({
  ...field,
  ...props,
  value: field.value,
  checked: field.value
})

const getOtherProps = (props) => {
  const {
    field,
    form,
    ...otherProps
  } = props

  return otherProps
}

const areEqual = (prevProps, nextProps) => {
  const field = nextProps.field.name
  const test = type => getIn(prevProps.form[type], field) !== getIn(nextProps.form[type], field)
  const diffValue = prevProps.field.value !== nextProps.field.value
  const fields = ['errors', 'touched', 'isSubmitting']

  if (diffValue || fields.some(test)) {
    return false
  }

  const prevOtherProps = getOtherProps(prevProps)
  const nextOtherProps = getOtherProps(nextProps)

  return isShallowEqual(prevOtherProps, nextOtherProps)
}

export {
  getFieldProps,
  getSwitchProps,
  areEqual
}
