const isObject = collection => Object.prototype.toString.call(collection) === '[object Object]'

const forEach = (collection, callback, scope) => {
  if (isObject(collection)) {
    for (const property in collection) {
      if (Object.prototype.hasOwnProperty.call(collection, property)) {
        callback.call(scope, collection[property], property, collection)
      }
    }
  } else {
    const size = collection.length

    for (let i = 0; i < size; i += 1) {
      callback.call(scope, collection[i], i, collection)
    }
  }
}

const map = (collection, callback) => {
  if (!collection) return []
  if (Array.isArray(collection)) return collection.map(callback)
  if (isObject(collection)) {
    return Object.keys(collection).map(key => callback(collection[key], key, collection))
  }

  return []
}

const completeArray = (array, arrayMinSize, fillValue) => [...array, ...Array(arrayMinSize - array.length).fill(fillValue)]

const chunk = (array, chunkSize, filled) => Array.from({ length: Math.ceil((array ? array.length : 0) / chunkSize) })
  .map((_, index) => {
    const start = index * chunkSize
    const end = start + chunkSize
    const set = array.slice(start, end)
    return filled ? completeArray(set, chunkSize, null) : set
  })

const getDiffKeys = (list, compare) => Object.keys(list).filter(key => !compare.hasOwnProperty(key))

const isShallowEqual = (x, y) => {
  const keys = Object.keys(x)

  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i]

    if (x[key] !== y[key]) {
      return false
    }
  }

  return true
}

const reduce = (collection, callback, initialValue) => {
  if (!collection) return []
  if (Array.isArray(collection)) return collection.reduce(callback, initialValue)
  if (isObject(collection)) {
    return Object.keys(collection)
      .reduce((acc, key) => callback(acc, collection[key], key, collection), initialValue)
  }

  return []
}

const defaultChecker = (x, y) => x === y
const differenceWith = (listA, listB, checker = defaultChecker) => listA.filter(itemA => !listB.find(itemB => checker(itemA, itemB)))

const hasDifferenceWith = (...args) => !!differenceWith(...args).length

const hasDifferenceWithBoth = (listA, listB, checker) => hasDifferenceWith(listA, listB, checker) || hasDifferenceWith(listB, listA, checker)

export {
  completeArray,
  map,
  forEach,
  hasDifferenceWith,
  chunk,
  hasDifferenceWithBoth,
  getDiffKeys,
  differenceWith,
  isObject,
  isShallowEqual,
  reduce
}
