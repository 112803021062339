import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const District = lazy(() => lazyRetry(() => import('containers/District')))
const DistrictRegister = lazy(() => lazyRetry(() => import('containers/District/register')))

const route = [
  {
    name: i18n.t('district.title'),
    path: '/district',
    main: District,
    exact: true,
    permissions: ['ROLE_CADASTRO_MUNICIPIO_LEITURA']
  },
  {
    name: i18n.t('district.registerTitle'),
    path: '/district/register',
    main: DistrictRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_MUNICIPIO_ESCRITA']
  },
  {
    name: i18n.t('distributor.editTitle'),
    path: '/district/edit/:id',
    main: DistrictRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_MUNICIPIO_ESCRITA']
  }
]

export default route
