import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#e23041',
      background: '#FED388',
      contrastText: '#fff'
    },
    error: {
      main: '#eb0000',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
})

export default theme
