import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const CampaignRefund = lazy(() => lazyRetry(() => import('containers/CampaignRefund')))

const route = [
  {
    name: i18n.t('campaignRefund.title'),
    path: '/campaign-refund',
    main: CampaignRefund,
    exact: true,
    permissions: ['ROLE_GESTAO_DOCUMENTOS_ELETRONICOS_LEITURA']
  }
]

export default route
