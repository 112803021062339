import styled from 'styled-components'

import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Helvetica';
    box-sizing: border-box;
    outline: none;
  }

  body {
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: #fafafa;
  }
`


export const StyledContainer = styled.div`
    white-space: pre-wrap;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-flow: column;

    :before {
        content: '';
        top: 0px;
        left: 0px;
        right: 0px;
        width: 100vw;
        height: 20vh;
        background-color: #ef5350;
        position: fixed;
    }

    .card {
        display: flex;
        align-content: center;
        justify-content: center;
        flex-flow: column;
        flex-wrap: wrap;
        padding: 48px;
        width: 900px;
        position: absolute;
        top: 8vh;
        left: 0px;
        right: 0px;
        margin: auto;
        box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.87);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #fff;
    }

    .card .card-header {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;
    }

    .card .fas {
        color: #9e9e9e;
    }

    .card .fa-exclamation-triangle {
        color: #fdd835;
        position: relative;
        margin-right: 12px;
        font-size: 40px;
    }

    .card .title {
        color: #000;
        text-align: center;
        font-size: 24px;
    }

    .card .sub-title {
        width: 100%;
        color: #4a4a4a;
        text-align: center;
        font-size: 18px;
    }

    .card .card-body {
        max-height: 480px;
        overflow: auto;
        padding: 12px;
        background-color: rgba(206, 17, 38, 0.1);
        border-radius: 4px;
        position: relative;
    }

    .card .card-body .text {
        font-size: 14px;
        color: #ef5350;
        line-height: 1.4;
    }

    .actions-box {
        height: 0px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        position: sticky;
        right: 12px;
        top: 12px;
        z-index: 10;
    }

    .actions-box .basic-button {
        background-color: #2196F3;
        color: #ffffff;
        margin: 0px 4px;
        font-size: 12px;
        text-decoration: none !important;
        padding: 4px;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        line-height: 1;
    }

    .actions-box .basic-button:hover {
        background-color: #1976d2;
    }
`