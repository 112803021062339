import React from 'react'
import styled, { css } from 'styled-components'
import Button from '@material-ui/core/Button'

export const StyledButton = styled(({ underlined, fontColor, fontSize, backgroundColor, fontWeight, noPaddingLeft, noFixedHeight, noPadding, justifyContent, noBorder, ...props }) => <Button {...props} />)`
  padding-left: 24px;
  padding-right: 24px;
  ${({ noFixedHeight }) => (noFixedHeight ? '' : 'height: 40px')};
  border-width: 2px;
  white-space: nowrap;
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ fontColor }) => fontColor};
  background: ${({ backgroundColor }) => backgroundColor};
  text-decoration: ${({ underlined }) => (underlined ? 'underline' : 'none')};
  font-weight: ${({ fontWeight }) => fontWeight};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  &.MuiButton-outlinedPrimary {
    border: 2px solid #e53935;
  }
  .MuiButton-label {
    font-weight: 500;
  }
`

export const StyledTextButton = styled(({ underlined, fontColor, fontSize, backgroundColor, fontWeight, noPaddingLeft, justifyContent, noPadding, noFixedHeight, noBorder, ...props }) => <Button {...props} />)`
  ${({ noFixedHeight }) => (noFixedHeight ? '' : 'height: 40px')};
  ${({ noPadding }) => (noPadding ? css`
  padding: 0;
  .MuiButton-startIcon{
    margin-left: 0;
  }
  ` : '')};
  white-space: nowrap;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  ${({ noPaddingLeft }) => noPaddingLeft && css`padding-left: 10px;`}
  ${({ noBorder }) => noBorder && css`border: none;`}
  &, &:hover {
    background: transparent;
    box-shadow: none;
    font-size: ${({ fontSize }) => fontSize};
    color: ${({ fontColor }) => fontColor};
    background: ${({ backgroundColor }) => backgroundColor};
    text-decoration: ${({ underlined }) => (underlined ? 'underline' : 'none')};
    font-weight: ${({ fontWeight }) => fontWeight};
  }
  .MuiButton-label {
    font-weight: 500;
  }
`
