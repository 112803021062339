import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const ReportsNotRefunded = lazy(() => lazyRetry(() => import('containers/ReportsNotRefunded')))

const route = [
  {
    name: i18n.t('reportsNotRefunded.title'),
    path: '/reports-not-refunded',
    main: ReportsNotRefunded,
    exact: true,
    permissions: ['ROLE_RELATORIO_NAO_REEMBOLSADOS']
  }
]

export default route
