import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const GroupXDistributor = lazy(() => lazyRetry(() => import('containers/GroupXDistributor')))
const GroupXDistributorRegister = lazy(() => lazyRetry(() => import('containers/GroupXDistributor/register')))

const route = [
  {
    name: i18n.t('groupXDistributor.title'),
    path: '/group-x-distributor',
    main: GroupXDistributor,
    exact: true,
    permissions: ['ROLE_CADASTRO_GRUPO_DISTRIBUIDOR_LEITURA']
  },
  {
    name: i18n.t('groupXDistributor.registerTitle'),
    path: '/group-x-distributor/register',
    main: GroupXDistributorRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_GRUPO_DISTRIBUIDOR_ESCRITA']
  },
  {
    name: i18n.t('groupXDistributor.editTitle'),
    path: '/group-x-distributor/edit/:id',
    main: GroupXDistributorRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_GRUPO_DISTRIBUIDOR_ESCRITA']
  }
]

export default route
