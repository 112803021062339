import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

export const StyledContainer = styled.div`
  position: absolute;
  top: 60px;
  left: 7.35%;
  z-index: 1;
`

export const StyledLastCrumb = styled.span`
  font-size: 12px;
  line-height: 1.33;
  color: #555a5f;
`

export const StyledLink = styled(({ children, ...props }) => (
  <Link {...props}>{children}</Link>
))`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  color: #212529;
`

export const StyledIcon = styled(({ ...props }) => (
  <ChevronRightIcon {...props} />
))`
  font-size: 16px;
  vertical-align: middle;
`
