import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  header,
  #root > header ~ div,
  #root > header ~ div:last-of-type > div:first-of-type {
    display: none !important;
  }

  #root > header ~ div:last-of-type {
    display: initial !important;
  }

  * {
    font-family: 'Helvetica';
    box-sizing: border-box;
    outline: none;
  }

  body {
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    background-color: #fafafa;
  }

  #errorContainer {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #errorContainer:before {
    content: '';
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100vw;
    height: 20vh;
    background-color: #ef5350;
    position: fixed;
  }

  #errorContainer .card {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 48px;
    width: 464px;
    position: absolute;
    top: 8vh;
    left: 0px;
    right: 0px;
    margin: auto;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
  }

  #errorContainer .card .card-header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  #errorContainer .card .fas {
    color: #9e9e9e;
  }

  #errorContainer .card .fa-tools {
    color: #eeeeee;
    position: absolute;
    font-size: 40px;
  }

  #errorContainer .card .fa-cloud {
    font-size: 90px;
  }

  #errorContainer .card .card-body .title {
    color: #000;
    text-align: center;
    font-size: 22px;
  }

  #errorContainer .card .card-body .text {
    font-size: 16px;
    color: #000;
    text-align: justify;
    line-height: 1.4;
  }

  #errorContainer .card .card-body .text.luizalabs {
    color: #888888;
  }
`
