import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const RequestRefund = lazy(() => lazyRetry(() => import('containers/RequestRefund')))
const RequestRefundRegister = lazy(() => lazyRetry(() => import('containers/RequestRefund/register')))

const route = [
  {
    name: i18n.t('requestRefund.title'),
    path: '/request-refund',
    main: RequestRefund,
    exact: true,
    permissions: ['ROLE_SOLICITACAO_REEMBOLSO_SEM_NOTA_FISCAL_LEITURA']
  },
  {
    name: i18n.t('requestRefund.registerTitle'),
    path: '/request-refund/register',
    main: RequestRefundRegister,
    exact: true,
    permissions: ['ROLE_SOLICITACAO_REEMBOLSO_SEM_NOTA_FISCAL_ESCRITA']
  },
  {
    name: i18n.t('requestRefund.editTitle'),
    path: '/request-refund/edit/:id',
    main: RequestRefundRegister,
    exact: true,
    permissions: ['ROLE_SOLICITACAO_REEMBOLSO_SEM_NOTA_FISCAL_ESCRITA']
  }
]

export default route
