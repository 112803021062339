import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Segment = lazy(() => lazyRetry(() => import('containers/Segment')))
const SegmentRegister = lazy(() => lazyRetry(() => import('containers/Segment/register')))

const route = [
  {
    name: i18n.t('segment.title'),
    path: '/segment',
    main: Segment,
    exact: true,
    permissions: ['ROLE_CADASTRO_SEGMENTO_LEITURA']
  },
  {
    name: i18n.t('segment.registerTitle'),
    path: '/segment/register',
    main: SegmentRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_SEGMENTO_ESCRITA']
  },
  {
    name: i18n.t('segment.editTitle'),
    path: '/segment/edit/:id',
    main: SegmentRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_SEGMENTO_ESCRITA']
  }
]

export default route
