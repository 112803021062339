import { createGlobalStyle } from 'styled-components'
import caretL from '../../assets/icons/caret-left.svg'
import caretR from '../../assets/icons/caret-right.svg'
import caretT from '../../assets/icons/caret-top.svg'
import caretD from '../../assets/icons/caret-down.svg'

const globalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600,700,800,900&display=swap');

  * {
    box-sizing: border-box;
    outline: none;
  }
  
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-button {
    background-size: 100%;
    height: 12px;
    width: 12px;
  }

  ::-webkit-scrollbar-button:end {
    display: block;
  }

  ::-webkit-scrollbar-button:start {
    display: block;
  }

  ::-webkit-scrollbar-button:horizontal:increment {
    background-image: url(${caretR});
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: contain;
  }

  ::-webkit-scrollbar-button:horizontal:decrement {
    background-image: url(${caretL});
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: contain;
  }

  ::-webkit-scrollbar-button:vertical:increment {
    background-image: url(${caretD});
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: contain;
  }

  ::-webkit-scrollbar-button:vertical:decrement {
    background-image: url(${caretT});
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: contain;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #989898;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #808080;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active{
      -webkit-box-shadow: 0 0 0 30px #ffedec inset !important;
  }

  body {
    font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    margin: 0;

    .css-19jh2ze-option {
      background-color: transparent;
    }

    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    ::-webkit-scrollbar-button:end {
      display: none;
    }
  
    ::-webkit-scrollbar-button:start {
      display: none;
    }
  }
`

export default globalStyle
