/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { memo } from 'react'
import { Field } from 'formik'
import { areEqual } from './utils'
import FormControlFile from '../FormControlFile/index'

const withFieldFile = (Input) => {
  const FieldComponent = props => <FormControlFile {...props} customComponent={Input} />
  const component = memo(FieldComponent, areEqual)

  return props => <Field {...props} component={component} />
}

export default withFieldFile
