/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import toast from 'common/utils/toast'
import Modal from 'components/Modal'
import ChangePasswordForm from './ChangePasswordForm'
import { Formik } from 'formik'
import * as yup from 'yup'
import sha512 from 'crypto-js/sha512'
import md5 from 'crypto-js/md5'
import userApi from 'resources/user'
import { messageServiceError } from 'help/message'

const strongPassword = /^(?!.* )(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-._]).{8,}$/

const validationSchema = yup.object().shape({
  oldPassword: yup.string().required('general.fieldRequired'),
  newPassword: yup.string().matches(strongPassword, 'general.strongPasswordDidntMatch').required('general.fieldRequired'),
  newPasswordConfirmation: yup.string().test('match', 'general.passwordsDidntMatch', function(newPasswordConfirmation) {
    const { newPassword } = this.options.parent
    return newPasswordConfirmation === newPassword
  }).required('general.fieldRequired')
})

const initialValues = {
  oldPassword: '',
  newPassword: '',
  newPasswordConfirmation: ''
}

function ChangePasswordModal ({ modalState, setModalState, logout }) {
  const { t } = useTranslation()
  const { openModal } = modalState

  const [submittingData, setSubmittingData] = useState(false)

  const handleToggleModal = useCallback((opened) => {
    setModalState(prevState => ({
      ...prevState,
      openModal: opened
    }))
  }, [setModalState])

  const sendData = useCallback((values, { setSubmitting }) => {
    const getEncryptedPassword = password => sha512(md5(password).toString().toUpperCase()).toString().toUpperCase()
    const { newPassword, oldPassword } = values
    setSubmittingData(true)
    userApi.changePassword({
      desSenha: getEncryptedPassword(newPassword),
      desSenhaAntiga: getEncryptedPassword(oldPassword)
    })
      .then(() => {
        toast.success(t('general.passwordSuccessfullyChanged'))
        handleToggleModal(false)
        logout()
      })
      .catch(messageServiceError)
      .finally(() => {
        setSubmittingData(false)
        setSubmitting(false)
      })
  }, [handleToggleModal, logout, t])

  return openModal ? (
    <Modal
      showModal={openModal}
      toggleModal={!submittingData ? handleToggleModal : () => {}}
      disableBackdropClick={submittingData}
      modalTitle={t('general.changePassword')}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={sendData}>
        {props => <ChangePasswordForm {...props} handleToggleModal={handleToggleModal} />}
      </Formik>
    </Modal>) : null
}

ChangePasswordModal.propTypes = {
  modalState: PropTypes.shape({
    openModal: PropTypes.bool,
    data: PropTypes.object
  }).isRequired,
  setModalState: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
}

export default ChangePasswordModal
