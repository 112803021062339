import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { StyledContainer, StyledLastCrumb, StyledLink, StyledIcon } from './styled'

const RenderCrumbs = ({ crumbs }) => crumbs.map(({ name, path }, key) => {
  if (key + 1 === crumbs.length) {
    return (
      <StyledLastCrumb key={key}>
        {name}
      </StyledLastCrumb>
    )
  }

  return (
    <Fragment key={key}>
      <StyledLink to={path}>
        {name}
      </StyledLink>
      <StyledIcon />
    </Fragment>
  )
})

const Breadcrumbs = ({ crumbs }) => {
  if (crumbs.length <= 1) {
    return null
  }

  return (
    <StyledContainer>
      <RenderCrumbs crumbs={crumbs} />
    </StyledContainer>
  )
}

Breadcrumbs.propTypes = {
  crumbs: PropTypes.array.isRequired
}

export default Breadcrumbs
