import React from 'react'
import PropTypes from 'prop-types'
import { Route, useLocation, useHistory } from 'react-router-dom'
import _get from 'lodash/get'
import { isTokenValid } from 'help/jwt'

function ProtectedRoute({
  component: Component,
  permissions,
  ...rest
}) {
  const location = useLocation()
  const history = useHistory()

  const accessToken = window.localStorage.getItem('access_token')
  const expiredToken = window.localStorage.getItem('expiredToken')

  const validToken = isTokenValid(accessToken) && expiredToken !== 'true'

  if (!validToken) {
    window.localStorage.clear()
    window.localStorage.setItem('expiredToken', true)
    history.push({
      pathname: '/login',
      state: {
        from: location
      }
    })
  }

  return (<Route
    {...rest}
    const
    render={(props) => {
      const permissionsMap = permissions || {}
      const pathname = _get(rest, 'location.pathname', '')
      const hasPermission = permissionsMap[pathname] || permissionsMap[rest.path]
      if ((rest.path !== '/sandbox' && !validToken) || !hasPermission) {
        return null
      }
      return <Component {...props} />
    }}
  />)
}

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired
}

export default ProtectedRoute
