import React from 'react'
import _size from 'lodash/size'
import { get, setIn } from 'common/utils/object'
import { StoreContext } from './context'

const getItem = (context, defaultValue) => (name) => {
  if (name) {
    const currentStore = get(context, `store.${name}`, {})

    return { ...defaultValue, ...currentStore }
  }

  return get(context, 'store')
}

const setItem = (context, name) => (state, callback) => {
  const { store } = context
  const newState = { ...store[name], ...state }
  const newStore = setIn(store, name, newState)

  context.set(newStore, name, callback)
}

const removeItem = (context, name) => (key, callback) => {
  const currentStore = context.store[name]
  const newStore = { ...currentStore }

  delete newStore[key]

  context.remove({ ...context.store, [name]: newStore }, name, callback)
}

const setItemWithoutMerge = (context, name) => (state, callback) => {
  const { store } = context
  const newStore = setIn(store, name, state)

  context.set(newStore, name, callback)
}

export default function useStore(name, defaultValue = {}) {
  const context = React.useContext(StoreContext)
  const resetStore = get(context, 'reset')
  const getStore = getItem(context, defaultValue)

  if (!name) {
    return { resetStore, getStore }
  }

  const setStore = setItem(context, name)
  const setStoreWithoutMerge = setItemWithoutMerge(context, name)
  const removeStore = removeItem(context, name)
  const currentStore = get(context, `store.${name}`, {})
  const store = _size(currentStore) ? currentStore : defaultValue

  return { store, setStore, removeStore, resetStore, getStore, setStoreWithoutMerge }
}
