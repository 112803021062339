import React from 'react'
import logoKIBOM from 'assets/images/kibon.svg'
import logoChrome from 'assets/images/chrome-logo.svg'
import GlobalStyle from 'common/styles/global'
import { useTranslation } from 'react-i18next'
import { browsersVersionValid, browserNames } from 'common/utils/detectBrowsers'
import {
  StyledContainer,
  StyledBox,
  StyledImage,
  StyledChromeImage,
  StyledChromeImageContainer,
  StyledTextContainer,
  StyledText,
  StyledTextStrong,
  StyledLink,
  StyledLinkText
} from './style'

function InvalidBrowserPage() {
  const CHROME_DOWNLOAD_URL = 'https://chromeenterprise.google/browser/download/'
  const { t } = useTranslation()

  return (
    <StyledContainer>
      <GlobalStyle/>
      <StyledBox>
        <StyledImage src={logoKIBOM}/>
        <StyledTextContainer>
          <StyledText>
            {t('general.invalidBrowserPageText.intro')}
            <StyledTextStrong>
              {t('general.invalidBrowserPageText.serdName')}
            </StyledTextStrong>
            {t('general.invalidBrowserPageText.bestExperience')}
            <StyledTextStrong>
              {t('general.invalidBrowserPageText.googleChromeName')}
            </StyledTextStrong>
            {t(
              'general.invalidBrowserPageText.ending',
              browsersVersionValid.find(item => item.name === browserNames.CHROME_NAME)
            )}
          </StyledText>
          <StyledText color={'#888888'}>
            {t('general.invalidBrowserPageText.technicalTeamLuizalabs')}
          </StyledText>
        </StyledTextContainer>
        <StyledChromeImageContainer>
          <StyledLink
            title={t('general.invalidBrowserPageText.downloadGoogleChrome')}
            href={CHROME_DOWNLOAD_URL}
            target='_blank'
          >
            <StyledChromeImage src={logoChrome}/>
            <StyledLinkText>
              {t('general.invalidBrowserPageText.downloadGoogleChrome')}
            </StyledLinkText>
          </StyledLink>
        </StyledChromeImageContainer>
      </StyledBox>
    </StyledContainer>
  )
}

export default InvalidBrowserPage
