import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { FormControl } from '@material-ui/core'
import { getFieldProps, areEqual } from '../Field/utils'

function FormControlFile ({ customComponent: Input, ...props }) {
  const {
    id,
    fullWidth,
    required,
    margin,
    error,
    ...fieldProps
  } = getFieldProps(props)

  return (
    <FormControl component="fieldset" error={error} fullWidth={fullWidth} required={required} margin={margin}>
      <Input id={id} {...fieldProps} {...props} />
    </FormControl>
  )
}

FormControlFile.defaultProps = {
  margin: 'none',
  required: false,
  fullWidth: true,
  confirmExclusion: false
}

FormControlFile.propTypes = {
  customComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]).isRequired,
  margin: PropTypes.string,
  required: PropTypes.bool
}

export default memo(FormControlFile, areEqual)
