import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const ReportsUnprocessedNotes = lazy(() => lazyRetry(() => import('containers/ReportsUnprocessedNotes')))

const route = [
  {
    name: i18n.t('reportsUnprocessedNotes.title'),
    path: '/reports-unprocessed-notes',
    main: ReportsUnprocessedNotes,
    exact: true,
    permissions: ['ROLE_RELATORIO_PROCESSAMENTO']
  }
]

export default route
