import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Costumer = lazy(() => lazyRetry(() => import('containers/Customer')))
const CostumerRegister = lazy(() => lazyRetry(() => import('containers/Customer/register')))

const route = [
  {
    name: i18n.t('customer.title'),
    path: '/customer',
    main: Costumer,
    exact: true,
    permissions: ['ROLE_CADASTRO_CLIENTE_LEITURA']
  },
  {
    name: i18n.t('customer.registerTitle'),
    path: '/customer/register',
    main: CostumerRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_CLIENTE_ESCRITA']
  },
  {
    name: i18n.t('customer.editTitle'),
    path: '/customer/edit/:id',
    main: CostumerRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_CLIENTE_ESCRITA']
  }
]

export default route
