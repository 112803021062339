import React, { memo, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import ExpandableItem from './ExpandbleItem'
import SingleIntem from './SingleItem'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import MenuOpenIcon from '@material-ui/icons/MenuOpen'
import MenuIcon from '@material-ui/icons/Menu'
import HomeIcon from '@material-ui/icons/Home'
import _get from 'lodash/get'
import {
  StyledListItem,
  StyledListItemText
} from './styles'

function ItemsList ({
  items,
  menuState,
  setMenuState,
  activeMenuItem
}) {
  const { t } = useTranslation()

  const history = useHistory()
  const { pathname } = useLocation()

  const handleMenuToggle = useCallback((evt) => {
    evt.stopPropagation()
    setMenuState(currentState => ({
      opened: !currentState.opened,
      blocked: false
    }))
  }, [setMenuState])

  const handleBackHome = useCallback(() => {
    setMenuState({
      blocked: false,
      opened: false
    })
    history.push('/')
  }, [history, setMenuState])

  const menuOpened = _get(menuState, 'opened', false)

  const menuItems = useMemo(() => items.map((item, index) => {
    const selected = activeMenuItem === _get(item, 'menuItem', false)
    const {
      subItems = [],
      path = ''
    } = item
    const key = `${path}-${index}`
    if (subItems.length) {
      return <ExpandableItem key={key} {...item} menuOpened={menuOpened} selected={selected} />
    }
    return <SingleIntem key={key} {...item} selected={selected} />
  }), [activeMenuItem, items, menuOpened])

  return (
    items.length > 0 &&
      <List component="div" dataTestId="menuSideBar">
        <>
          <ListItem onClick={handleMenuToggle} key="toglleMenu" id="meuIcon">
            <ListItemIcon>{menuOpened ? <MenuOpenIcon /> : <MenuIcon />}</ListItemIcon>
          </ListItem>
          <StyledListItem onClick={handleBackHome} key="home" id="home" selected={pathname === '/'}>
            <ListItemIcon><HomeIcon /></ListItemIcon>
            <StyledListItemText>{t('menu.homepage')}</StyledListItemText>
          </StyledListItem>
          {menuItems}
        </>
      </List>
  )
}

ItemsList.propTypes = {
  items: PropTypes.array.isRequired,
  menuState: PropTypes.object.isRequired,
  setMenuState: PropTypes.func.isRequired,
  activeMenuItem: PropTypes.string
}

ItemsList.defaultProps = {
  activeMenuItem: ''
}

export default memo(ItemsList)
