import Home from './home'
import User from './user'
import EconomicGroup from './economicGroup'
import Region from './region'
import Regional from './regional'
import Profile from './profile'
import Distributor from './distributor'
import ErrorPage from './errorPage'
import Potential from './potential'
import BusinessType from './businessType'
import Segment from './segment'
import Channel from './channel'
import District from './district'
import Customer from './customer'
import Category from './category'
import Product from './product'
import PriceTable from './priceTable'
import ResponsibleChannel from './responsibleChannel'
import Charge from './charge'
import ApprovalGroup from './approvalGroup'
import ApprovalFlow from './approvalFlow'
import SkuCode from './skuCode'
import GroupXDistributor from './groupXDistributor'
import TradeAction from './tradeAction'
import PolicyDifference from './policyDifference'
import PunctualBonus from './punctualBonus'
import NoNfAction from './noNfAction'
import TrousseauBonusKa from './trousseauBonusKa'
import CustomerSpecific from './customerSpecific'
import ParishRoute from './parishRoute'
import RequestRefund from './requestRefund'
import Closure from './closure'
import CustomerRefund from './customerRefund'
import CampaignRefund from './campaignRefund'
import NfesImporter from './nfesImporter'
import ReprocessNfes from './reprocessNfes'
import FiscalNotes from './fiscalNotes'
import Campaigns from './campaigns'
import RefundsManualAdjustments from './refundsManualAdjustments'
import RefundsFiscalNotes from './refundsFiscalNotes'
import RefundsRefundRequests from './refundsRefundRequests'
import ReportsContestation from './reportsContestation'
import ReportsInvoicing from './reportsInvoicing'
import ReportsUnprocessedNotes from './reportsUnprocessedNotes'
import ReportsNotRefunded from './reportsNotRefunded'
import ReportsCustomerImport from './reportsCustomerImport'
import ReportsRefund from './reportsRefund'
import ChangeExpirationDate from './changeExpirationDate'
import ManualAdjustment from './manualAdjustment'

const routes = [
  ...Home,
  ...ReportsRefund,
  ...ReportsCustomerImport,
  ...ReportsNotRefunded,
  ...ReportsUnprocessedNotes,
  ...ReportsInvoicing,
  ...ReportsContestation,
  ...RefundsRefundRequests,
  ...RefundsFiscalNotes,
  ...RefundsManualAdjustments,
  ...Campaigns,
  ...FiscalNotes,
  ...ReprocessNfes,
  ...NfesImporter,
  ...CampaignRefund,
  ...CustomerRefund,
  ...Closure,
  ...RequestRefund,
  ...GroupXDistributor,
  ...SkuCode,
  ...ApprovalFlow,
  ...Charge,
  ...ApprovalGroup,
  ...ResponsibleChannel,
  ...PriceTable,
  ...Product,
  ...Category,
  ...Customer,
  ...District,
  ...Channel,
  ...Segment,
  ...Potential,
  ...BusinessType,
  ...User,
  ...EconomicGroup,
  ...Region,
  ...Regional,
  ...Profile,
  ...Distributor,
  ...TradeAction,
  ...PolicyDifference,
  ...PunctualBonus,
  ...NoNfAction,
  ...TrousseauBonusKa,
  ...CustomerSpecific,
  ...ParishRoute,
  ...ChangeExpirationDate,
  ...ManualAdjustment,
  ...ErrorPage
]

export const routesPermissions = [...routes]

export default routes
