import Api from './api/apiGPC'
import { buildRequestParamsObj } from 'help/requests'

const baseUrl = '/actuator'

const health = {
  check() {
    return Api.request(`${baseUrl}/health`, buildRequestParamsObj(null, Api.getHeaders(), 'GET'))
  },
  doubleCheck() {
    return Api.request(`${baseUrl}/info`, buildRequestParamsObj(null, Api.getHeaders(), 'GET'))
  }
}
export default health
