import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import CloseIcon from '@material-ui/icons/Close'
import {
  StyledModal,
  StyledModalContent,
  StyledModalTitle,
  StyledModalDescription,
  StyledCloseModalButton,
  StyledModalSubDescription
} from './styled'

function Modal({
  showModal,
  toggleModal,
  children,
  modalTitle,
  modalDescription,
  modalSubDescription,
  modalWidth,
  buttonRowComponent,
  absoluteModal,
  scrollable,
  customPadding,
  onBackdropClick,
  disableBackdropClick,
  disableClose,
  customModalTitlePadding,
  ...props
}) {
  const handleBackdropClick = useCallback(() => {
    if (!disableBackdropClick) {
      if (onBackdropClick) {
        onBackdropClick()
      }
    }
  }, [disableBackdropClick, onBackdropClick])

  const modalSubDescriptionExists = modalSubDescription.length > 0

  return (
    <StyledModal
      {...props}
      open={showModal}
      onClose={() => toggleModal(false)}
      onBackdropClick={handleBackdropClick}
      disableBackdropClick={disableBackdropClick}
    >
      <StyledModalContent
        modalWidth={modalWidth}
        scrollable={scrollable}
        customPadding={customPadding}
      >
        <StyledCloseModalButton
          onClick={() => toggleModal(false)}
          disabled={disableClose}
          dataTestId="closeModalButton"
        >
          <CloseIcon />
        </StyledCloseModalButton>
        <StyledModalTitle customModalTitlePadding={customModalTitlePadding}>
          {modalTitle}
        </StyledModalTitle>
        {modalDescription.length > 0 && (
          <StyledModalDescription hasSubdescription={modalSubDescriptionExists}>
            {modalDescription}
          </StyledModalDescription>)}
        {modalSubDescriptionExists && (
          <StyledModalSubDescription>
            { modalSubDescription }
          </StyledModalSubDescription>
        )}
        {children}
        {buttonRowComponent}
      </StyledModalContent>
    </StyledModal>
  )
}

Modal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  children: PropTypes.node,
  modalTitle: PropTypes.string,
  modalDescription: PropTypes.string,
  modalSubDescription: PropTypes.string,
  buttonRowComponent: PropTypes.node,
  modalWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  absoluteModal: PropTypes.bool,
  scrollable: PropTypes.bool,
  customPadding: PropTypes.string,
  onBackdropClick: PropTypes.func,
  disableBackdropClick: PropTypes.bool,
  disableClose: PropTypes.bool,
  customModalTitlePadding: PropTypes.string
}

Modal.defaultProps = {
  modalTitle: '',
  modalDescription: '',
  modalSubDescription: '',
  modalWidth: 530,
  children: null,
  absoluteModal: false,
  scrollable: false,
  buttonRowComponent: null,
  customPadding: '32px',
  onBackdropClick: null,
  disableBackdropClick: false,
  disableClose: false,
  customModalTitlePadding: ''
}

export default memo(Modal)
