import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const TrousseauBonusKa = lazy(() => lazyRetry(() => import('containers/TrousseauBonusKa')))
const TrousseauBonusKaRegister = lazy(() => lazyRetry(() => import('containers/TrousseauBonusKa/register')))

const route = [
  {
    name: i18n.t('trousseauBonusKa.title'),
    path: '/trousseau-bonus-ka',
    main: TrousseauBonusKa,
    exact: true,
    permissions: ['ROLE_CADASTRO_BONIFICACAO_ENXOVAL_KA_LEITURA']
  },
  {
    name: i18n.t('trousseauBonusKa.registerTitle'),
    path: '/trousseau-bonus-ka/register',
    main: TrousseauBonusKaRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_BONIFICACAO_ENXOVAL_KA_ESCRITA']
  },
  {
    name: i18n.t('trousseauBonusKa.editTitle'),
    path: '/trousseau-bonus-ka/edit/:id',
    main: TrousseauBonusKaRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_BONIFICACAO_ENXOVAL_KA_ESCRITA']
  },
  {
    name: i18n.t('trousseauBonusKa.copyTitle'),
    path: '/trousseau-bonus-ka/copy/:id',
    main: TrousseauBonusKaRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_BONIFICACAO_ENXOVAL_KA_ESCRITA']
  }
]

export default route
