import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Regional = lazy(() => lazyRetry(() => import('containers/Regional')))
const RegionalRegister = lazy(() => lazyRetry(() => import('containers/Regional/register')))

const route = [
  {
    name: i18n.t('regional.title'),
    path: '/sectional',
    main: Regional,
    exact: true,
    permissions: ['ROLE_CADASTRO_REGIONAL_LEITURA']
  },
  {
    name: i18n.t('regional.registerTitle'),
    path: '/sectional/register',
    main: RegionalRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_REGIONAL_ESCRITA']
  },
  {
    name: i18n.t('regional.editTitle'),
    path: '/sectional/edit/:id',
    main: RegionalRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_REGIONAL_ESCRITA']
  }
]

export default route
