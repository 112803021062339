import i18n from 'common/i18n'
import ErrorPage from 'containers/ErrorPage'

const route = [
  {
    name: i18n.t('routes.errorPage.index'),
    path: '/service-unavailable',
    main: ErrorPage,
    exact: true
  }
]

export default route
