import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const RefundsRefundRequests = lazy(() => lazyRetry(() => import('containers/RefundsRefundRequests')))

const route = [
  {
    name: i18n.t('refundsRefundRequests.title'),
    path: '/edocs-refunds-refund-requests',
    main: RefundsRefundRequests,
    exact: true,
    permissions: ['ROLE_GESTAO_DOCUMENTOS_ELETRONICOS_LEITURA']
  }
]

export default route
