import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Potential = lazy(() => lazyRetry(() => import('containers/Potential')))
const PotentialRegister = lazy(() => lazyRetry(() => import('containers/Potential/register')))

const route = [
  {
    name: i18n.t('potential.title'),
    path: '/potential',
    main: Potential,
    exact: true,
    permissions: ['ROLE_CADASTRO_POTENCIAL_LEITURA']
  },
  {
    name: i18n.t('potential.registerTitle'),
    path: '/potential/register',
    main: PotentialRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_POTENCIAL_ESCRITA']
  },
  {
    name: i18n.t('potential.editTitle'),
    path: '/potential/edit/:id',
    main: PotentialRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_POTENCIAL_ESCRITA']
  }
]

export default route
