import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const ReportsInvoicing = lazy(() => lazyRetry(() => import('containers/ReportsInvoicing')))

const route = [
  {
    name: i18n.t('reportsInvoicing.title'),
    path: '/reports-invoicing',
    main: ReportsInvoicing,
    exact: true,
    permissions: ['ROLE_RELATORIO_FATURAMENTO_DISTRIBUIDOR']
  }
]

export default route
