/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import Modal from '@material-ui/core/Modal'
import _isNumber from 'lodash/isNumber'

export const StyledModal = styled(({ modalWidth, actionList, absoluteModal, scrollable, handleClose, ...props }) => <Modal {...props} />)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto, sans-serif;
`

StyledModal.propTypes = {
  scrollable: PropTypes.bool,
  modalWidth: PropTypes.string
}

StyledModal.defaultProps = {
  scrollable: false,
  modalWidth: ''
}

export const StyledModalContent = styled(forwardRef(({ scrollable, modalWidth, customPadding, ...props }, ref) => <div {...props} ref={ref} />))` 
  background-color: white;
  border-radius: 4px;
  position: relative;
  ${({ modalWidth }) => css`width: ${_isNumber(modalWidth) ? `${modalWidth}px` : modalWidth};`}
  ${({ scrollable }) => scrollable && css`
    position: relative;
    overflow: auto;
    max-height: 80vh;
  `}
  ${({ customPadding }) => css`padding: ${customPadding};`}
`

export const StyledModalTitle = styled(({ customModalTitlePadding, ...props }) => <h2 {...props}>{props.children}</h2>)`
  ${({ customModalTitlePadding }) => customModalTitlePadding.length > 0 && css`padding: ${customModalTitlePadding};`}
  margin: 0;
  font-size: 30px;
  color: #212529;
  font-weight: 500;
  line-height: 1;
`

export const StyledCloseModalButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`

export const StyledModalDescription = styled(({ hasSubdescription, ...props }) => <p {...props} />)`
  margin: 0;
  color: #555a5f;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 8px;
  margin-bottom: ${({ hasSubdescription }) => (hasSubdescription ? '6' : '20')}px;
`

export const StyledModalSubDescription = styled.p`
  margin: 0;
  color: #555a5f;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 8px;
  margin-bottom: 20px;
`
