import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const ReportsCustomerImport = lazy(() => lazyRetry(() => import('containers/ReportsCustomerImport')))

const route = [
  {
    name: i18n.t('reportsCustomerImport.title'),
    path: '/reports-customer-import',
    main: ReportsCustomerImport,
    exact: true,
    permissions: ['ROLE_RELATORIO_IMPORTACAO_CLIENTE']
  }
]

export default route
