import {
  lazy
} from 'react'
import { lazyRetry } from '../help/utils'

const Login = lazy(() => lazyRetry(() => import('containers/Login')))

const route = {
  path: '/login',
  main: Login,
  exact: true
}

export default route
