import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const ParishRoute = lazy(() => lazyRetry(() => import('containers/ParishRoute')))
const ParishRouteRegister = lazy(() => lazyRetry(() => import('containers/ParishRoute/register')))

const route = [
  {
    name: i18n.t('parishRoute.title'),
    path: '/parish-route',
    main: ParishRoute,
    exact: true,
    permissions: ['ROLE_CADASTRO_REEMBOLSO_ROTA_FREGUESIA_LEITURA']
  },
  {
    name: i18n.t('parishRoute.registerTitle'),
    path: '/parish-route/register',
    main: ParishRouteRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_REEMBOLSO_ROTA_FREGUESIA_ESCRITA']
  },
  {
    name: i18n.t('parishRoute.editTitle'),
    path: '/parish-route/edit/:id',
    main: ParishRouteRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_REEMBOLSO_ROTA_FREGUESIA_ESCRITA']
  },
  {
    name: i18n.t('parishRoute.copyTitle'),
    path: '/parish-route/copy/:id',
    main: ParishRouteRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_REEMBOLSO_ROTA_FREGUESIA_ESCRITA']
  }
]

export default route
