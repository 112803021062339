import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const RefundsFiscalNotes = lazy(() => lazyRetry(() => import('containers/RefundsFiscalNotes')))

const route = [
  {
    name: i18n.t('refundsFiscalNotes.title'),
    path: '/edocs-refunds-fiscal-notes',
    main: RefundsFiscalNotes,
    exact: true,
    permissions: ['ROLE_GESTAO_DOCUMENTOS_ELETRONICOS_LEITURA']
  }
]

export default route
