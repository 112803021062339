import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const CustomerRefund = lazy(() => lazyRetry(() => import('containers/CustomerRefund')))

const route = [
  {
    name: i18n.t('customerRefund.title'),
    path: '/customer-refund',
    main: CustomerRefund,
    exact: true,
    permissions: ['ROLE_GESTAO_DOCUMENTOS_ELETRONICOS_LEITURA']
  }
]

export default route
