import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import {
  StyledListItemText,
  StyledListItem
} from './styles'
import { useStore } from 'components/Store'
import _isNil from 'lodash/isNil'
import moment from 'moment'

function SingleItem ({
  id,
  path,
  icon,
  title,
  closeMenu,
  selected,
  className,
  onClick,
  isSubitem
}) {
  const { setStore } = useStore('FILTER', {})
  const { setStoreWithoutMerge: setCycleFolderCounting } = useStore('CYCLE_FOLDER_COUNTING', {})

  const genId = () => {
    const pathSplited = !_isNil(path) && path.length > 0 ? path.split('/') : undefined
    return !_isNil(pathSplited) ? pathSplited[pathSplited.length - 1] : undefined
  }

  const location = useLocation()
  const identificator = id.length ? id : genId()

  return (
    <StyledListItem
      button
      id={identificator}
      dataTestId={title}
      className={className}
      selected={selected}
      component={Link}
      to={path && path.length > 0 ? path : location.pathname}
      onClick={(evt) => {
        evt.stopPropagation()
        closeMenu()
        onClick()
        if (path.length > 0) {
          setStore({ competence: moment().format('MM/YYYY') }, () => {
            setCycleFolderCounting({})
          })
        }
      }}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <StyledListItemText primary={title} isSubitem={isSubitem} />
    </StyledListItem>
  )
}

SingleItem.propTypes = {
  path: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  closeMenu: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isSubitem: PropTypes.bool,
  id: PropTypes.string
}

SingleItem.defaultProps = {
  path: '',
  selected: false,
  icon: null,
  className: undefined,
  onClick: () => {},
  isSubitem: false,
  id: ''
}

export default memo(SingleItem)
