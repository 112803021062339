import React, { Suspense, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import Loading from 'components/Loading'
import routes, { routesPermissions } from 'routes'
import StyledContainer from './style'
import ProtectedRoute from './components/ProtectedRoute'
import Breadcrumbs from './components/Breadcrumbs'
import Login from 'routes/login'
import { routesToPermissions } from 'common/utils/menu'
import { useStore } from 'components/Store'

function Main() {
  const [permissions, setPermissions] = useState({})
  const { store } = useStore('AUTH')

  function renderComponent(props, route) {
    const { match } = props

    const crumbs = routes
      .filter(({ path }) => match.path.includes(path))
      .map(({ path, ...rest }) => ({
        path: Object.keys(match.params).length ?
          Object.keys(match.params).reduce(
            (path, param) => path.replace(
              `:${param}`, match.params[param]
            ), path
          ) :
          path,
        ...rest
      }))

    return (
      <>
        {route.path.indexOf('/v1') === -1 &&
          <Breadcrumbs crumbs={crumbs} />
        }
        <route.main {...props} />
      </>
    )
  }

  useEffect(() => {
    setPermissions(routesToPermissions(routesPermissions, store.access_token))
  }, [setPermissions, store.access_token])

  return (
    <StyledContainer>
      <Suspense fallback={<Loading />}>
        <Switch>
          {routes.map(route => (
            <ProtectedRoute
              key={route.path}
              path={route.path}
              exact={route.exact}
              permissions={permissions}
              component={props => renderComponent(props, route)}
            />
          ))}
          <Route path={Login.path} exact={Login.exact} component={Login.main} />
          {/* <Route path="/404" /> */}
        </Switch>
      </Suspense>
    </StyledContainer>
  )
}

Main.propTypes = {
  match: PropTypes.object,
  route: PropTypes.object
}

Main.defaultProps = {
  match: {},
  route: {}
}

export default Main
