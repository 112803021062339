import moment from 'moment'
import 'moment/locale/pt-br'
import { getLanguage } from './locale'

moment.locale(getLanguage())

export function getPortugueseMomentInstance(ptDate) {
  const localizedInstance = moment().locale('pt-br')
  localizedInstance.set(ptDate)
  return localizedInstance
}

export default moment
