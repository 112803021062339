import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const NoNfAction = lazy(() => lazyRetry(() => import('containers/NoNfAction')))
const NoNfActionRegister = lazy(() => lazyRetry(() => import('containers/NoNfAction/register')))

const route = [
  {
    name: i18n.t('noNfAction.title'),
    path: '/no-nf-action',
    main: NoNfAction,
    exact: true,
    permissions: ['ROLE_CADASTRO_ACAO_SEM_NF_LEITURA']
  },
  {
    name: i18n.t('noNfAction.registerTitle'),
    path: '/no-nf-action/register',
    main: NoNfActionRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_ACAO_SEM_NF_ESCRITA']
  },
  {
    name: i18n.t('noNfAction.editTitle'),
    path: '/no-nf-action/edit/:id',
    main: NoNfActionRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_ACAO_SEM_NF_LEITURA']
  },
  {
    name: i18n.t('noNfAction.copyTitle'),
    path: '/no-nf-action/copy/:id',
    main: NoNfActionRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_ACAO_SEM_NF_ESCRITA']
  }
]

export default route
