import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Region = lazy(() => lazyRetry(() => import('containers/Region')))
const RegionRegister = lazy(() => lazyRetry(() => import('containers/Region/register')))

const route = [
  {
    name: i18n.t('region.title'),
    path: '/region',
    main: Region,
    exact: true,
    permissions: ['ROLE_CADASTRO_REGIAO_LEITURA']
  },
  {
    name: i18n.t('region.registerTitle'),
    path: '/region/register',
    main: RegionRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_REGIAO_ESCRITA']
  },
  {
    name: i18n.t('region.editTitle'),
    path: '/region/edit/:id',
    main: RegionRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_REGIAO_ESCRITA']
  }
]

export default route
