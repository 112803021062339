import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Campaigns = lazy(() => lazyRetry(() => import('containers/Campaigns')))

const route = [
  {
    name: i18n.t('campaigns.title'),
    path: '/edocs-campaigns',
    main: Campaigns,
    exact: true,
    permissions: ['ROLE_GESTAO_DOCUMENTOS_ELETRONICOS_LEITURA']
  }
]

export default route
