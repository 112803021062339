import general from './general'
import agreements from './agreements'
import menu from './menu'
import calculations from './calculations'
import tradeAgreementsAscertainment from './tradeAgreementsAscertainment'
import generalFilters from './generalFilters'
import payments from './payments'
import routes from './routes'
import calendar from './calendar'
import cycleFolder from './cycleFolder'
import user from './user'
import cva from './cva'
import channel from './channel'
import responsibleChannel from './responsibleChannel'
import groupXDistributor from './groupXDistributor'
import category from './category'
import customerAndSap from './customerAndSap'
import productIntegration from './productIntegration'
import clientIntegration from './clientIntegration'
import economicGroup from './economicGroup'
import home from './home'
import productsGroup from './productsGroup'
import impulseItem from './impulseItem'
import brands from './brands'
import microregion from './microregion'
import approvalFlow from './approvalFlow'
import internalOrder from './internalOrder'
import innovationProducts from './innovationProducts'
import profile from './profile'
import region from './region'
import regional from './regional'
import potential from './potential'
import businessType from './businessType'
import district from './district'
import customer from './customer'
import distributor from './distributor'
import charge from './charge'
import approvalGroup from './approvalGroup'
import product from './product'
import priceTable from './priceTable'
import budgetType from './budgetType'
import commodity from './commodity'
import sku from './sku'
import segment from './segment'
import subregion from './subregion'
import productPpa from './productPpa'
import questionsGoalExecution from './questionsGoalExecution'
import packCategory from './packCategory'
import customerPdv from './customerPdv'
import targetExecutionProduct from './targetExecutionProduct'
import targetExecutionCategory from './targetExecutionCategory'
import ppaProduct from './ppaProduct'
import ascertainment from './ascertainment'
import chat from './chat'
import pdvStoreIntegration from './pdvStoreIntegration'
import percentageValue from './percentageValue'
import sellin from './sellin'
import importsExportsDashboard from './importsExportsDashboard'
import inconsistenciesDashboard from './inconsistenciesDashboard'
import tradeAction from './tradeAction'
import policyDifference from './policyDifference'
import errorPage from './errorPage'
import punctualBonus from './punctualBonus'
import noNfAction from './noNfAction'
import trousseauBonusKa from './trousseauBonusKa'
import customerSpecific from './customerSpecific'
import parishRoute from './parishRoute'
import edocs from './edocs'
import requestRefund from './requestRefund'
import manualAdjustment from './manualAdjustment'
import closure from './closure'
import customerRefund from './customerRefund'
import campaignRefund from './campaignRefund'
import nfesImporter from './nfesImporter'
import changeExpirationDate from './changeExpirationDate'
import reprocessNfes from './reprocessNfes'
import fiscalNotes from './fiscalNotes'
import campaigns from './campaigns'
import refundsManualAdjustments from './refundsManualAdjustments'
import refundsFiscalNotes from './refundsFiscalNotes'
import refundsRefundRequests from './refundsRefundRequests'
import reportsContestation from './reportsContestation'
import reportsInvoicing from './reportsInvoicing'
import reportsUnprocessedNotes from './reportsUnprocessedNotes'
import reportsNotRefunded from './reportsNotRefunded'
import reportsCustomerImport from './reportsCustomerImport'
import reportsRefund from './reportsRefund'

export default {
  reportsRefund,
  reportsCustomerImport,
  reportsNotRefunded,
  reportsUnprocessedNotes,
  reportsInvoicing,
  reportsContestation,
  refundsRefundRequests,
  refundsFiscalNotes,
  refundsManualAdjustments,
  fiscalNotes,
  campaigns,
  reprocessNfes,
  changeExpirationDate,
  nfesImporter,
  campaignRefund,
  customerRefund,
  closure,
  manualAdjustment,
  requestRefund,
  edocs,
  general,
  generalFilters,
  agreements,
  menu,
  tradeAction,
  policyDifference,
  punctualBonus,
  noNfAction,
  trousseauBonusKa,
  customerSpecific,
  parishRoute,
  calculations,
  tradeAgreementsAscertainment,
  payments,
  routes,
  calendar,
  cycleFolder,
  user,
  cva,
  channel,
  responsibleChannel,
  groupXDistributor,
  category,
  customerAndSap,
  productIntegration,
  clientIntegration,
  economicGroup,
  home,
  productsGroup,
  impulseItem,
  brands,
  microregion,
  approvalFlow,
  profile,
  internalOrder,
  innovationProducts,
  budgetType,
  commodity,
  subregion,
  sku,
  targetExecutionCategory,
  targetExecutionProduct,
  segment,
  region,
  regional,
  potential,
  businessType,
  district,
  customer,
  distributor,
  charge,
  approvalGroup,
  product,
  priceTable,
  packCategory,
  questionsGoalExecution,
  customerPdv,
  productPpa,
  ppaProduct,
  ascertainment,
  pdvStoreIntegration,
  chat,
  percentageValue,
  sellin,
  importsExportsDashboard,
  inconsistenciesDashboard,
  errorPage
}
