import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const ReportsRefund = lazy(() => lazyRetry(() => import('containers/ReportsRefund')))

const route = [
  {
    name: i18n.t('reportsRefund.title'),
    path: '/reports-refund',
    main: ReportsRefund,
    exact: true,
    permissions: ['ROLE_RELATORIO_REEMBOLSO']
  }
]

export default route
