import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Closure = lazy(() => lazyRetry(() => import('containers/Closure')))
const ClosureRegister = lazy(() => lazyRetry(() => import('containers/Closure/register')))

const route = [
  {
    name: i18n.t('closure.title'),
    path: '/closure',
    main: Closure,
    exact: true,
    permissions: ['ROLE_FECHAMENTO_REEMBOLSO_LEITURA']
  },
  {
    name: i18n.t('closure.registerTitle'),
    path: '/closure/register',
    main: ClosureRegister,
    exact: true,
    permissions: ['ROLE_FECHAMENTO_REEMBOLSO_ESCRITA']
  },
  {
    name: i18n.t('closure.editTitle'),
    path: '/closure/edit/:id',
    main: ClosureRegister,
    exact: true,
    permissions: ['ROLE_FECHAMENTO_REEMBOLSO_ESCRITA']
  }
]

export default route
