import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const ManualAdjustment = lazy(() => lazyRetry(() => import('containers/ManualAdjustment')))
const ManualAdjustmentRegister = lazy(() => lazyRetry(() => import('containers/ManualAdjustment/register')))

const route = [
  {
    name: i18n.t('manualAdjustment.title'),
    path: '/manual-adjustment',
    main: ManualAdjustment,
    exact: true,
    permissions: ['ROLE_GESTAO_DOCUMENTOS_ELETRONICOS_AJUSTE_MANUAL']
  },
  {
    name: i18n.t('manualAdjustment.registerTitle'),
    path: '/manual-adjustment/register',
    main: ManualAdjustmentRegister,
    exact: true,
    permissions: ['ROLE_GESTAO_DOCUMENTOS_ELETRONICOS_AJUSTE_MANUAL']
  },
  {
    name: i18n.t('manualAdjustment.editTitle'),
    path: '/manual-adjustment/edit/:id',
    main: ManualAdjustmentRegister,
    exact: true,
    permissions: ['ROLE_GESTAO_DOCUMENTOS_ELETRONICOS_AJUSTE_MANUAL']
  }
]

export default route
