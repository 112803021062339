/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Provider } from './context'
import _clone from 'lodash/clone'
import moment from 'moment'

const initialStore = {
  AUTH: { access_token: '', isLogged: false },
  TRADEAGREEMENTSASCERTAINMENT: {},
  LEVER_EDIT: {},
  LEVER_RECOMMENDATION: null,
  FILTER: { competence: moment().format('MM/YYYY') }
}

const isDev = process.env.NODE_ENV !== 'production'
const hasDevTools = isDev && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__

class StoreProvider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      reset: this.reset,
      set: this.set,
      remove: this.remove,
      store: initialStore
    }
  }

  componentDidMount() {
    if (hasDevTools) {
      this.devTools = window.__REDUX_DEVTOOLS_EXTENSION__.connect()
    }

    const data = window.sessionStorage.getItem('store')
    const localStorageData = window.localStorage.getItem('data')

    if (data || localStorageData) {
      const store = {
        ...initialStore,
        ...(data ? JSON.parse(window.atob(data)) : {}),
        ...(localStorageData ? JSON.parse(window.atob(localStorageData)) : {})
      }
      this.logTools('@INIT/STORE', store)
      this.setState({ store })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.store !== nextState.store) {
      const { blackList } = nextProps
      this.logTools('@SAVE/LOCAL', nextState.store)
      const store = _clone(nextState.store)

      for (const key in store) {
        if (blackList.find(i => i === key) !== undefined) {
          delete (store[key])
        }
      }
      const jsonStore = JSON.stringify(store)
      const authStoreItem = JSON.stringify({ AUTH: store.AUTH })
      const data = window.btoa(jsonStore)
      const authData = window.btoa(authStoreItem)
      window.sessionStorage.setItem('store', data)
      window.localStorage.setItem('data', authData)
    }
    return this.state.store !== nextState.store
  }

  componentWillUnmount() {
    if (hasDevTools) {
      window.__REDUX_DEVTOOLS_EXTENSION__.disconnect()
    }
  }

  getInitialStore = () => {
    const { store } = this.state

    return { ...initialStore, AUTH: { ...store.AUTH } }
  }

  logTools = (name, state) => {
    if (hasDevTools) {
      this.devTools.send(name, state)
    }
  }

  resetCurrent = (name, callback) => {
    if (this.state.store.hasOwnProperty(name)) {
      const newStore = { ...this.state.store }

      delete newStore[name]

      this.setStore(newStore, `@RESET/${name}`, callback)
    }
  }

  reset = (name, callback) => {
    if (name && name === 'AUTH') {
      this.setStore(initialStore, '@RESET', callback)
    } else if (name) {
      this.resetCurrent(name, callback)
    } else {
      this.setStore(this.getInitialStore(), '@RESET', callback)
    }
  }

  setStore = (store, logName, callback) => {
    this.logTools(logName, store)
    this.setState({ store }, callback)
  }

  set = (store, name, callback) => {
    this.setStore(store, `@SET/${name}`, callback)
  }

  remove = (store, name, callback) => {
    this.setStore(store, `@REMOVE/${name}`, callback)
  }

  render() {
    return (
      <Provider value={this.state}>
        {this.props.children}
      </Provider>
    )
  }
}

StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
  blackList: PropTypes.arrayOf(PropTypes.string)
}

StoreProvider.defaultProps = {
  blackList: []
}

export default StoreProvider
