import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { FormHelperText, FormControl } from '@material-ui/core'
import { getFieldProps, areEqual } from '../Field/utils'
import FormLabel from '../FormLabel'

function FormControlQuantity ({ customComponent: Input, ...props }) {
  const {
    id,
    label,
    helperText,
    helperTextId,
    fullWidth,
    required,
    error,
    margin,
    ...fieldProps
  } = getFieldProps(props)

  return (
    <FormControl component="fieldset" error={error} fullWidth={fullWidth} required={required} margin={margin}>
      {label && <FormLabel className="pb-1" component="legend" htmlFor={id}>{label}</FormLabel>}
      <Input {...fieldProps} id={id} />
      {helperText && (
        <FormHelperText id={helperTextId}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

FormControlQuantity.defaultProps = {
  margin: 'normal',
  required: false
}

FormControlQuantity.propTypes = {
  customComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]).isRequired,
  margin: PropTypes.string,
  required: PropTypes.bool
}

export default memo(FormControlQuantity, areEqual)
