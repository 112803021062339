import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const Category = lazy(() => lazyRetry(() => import('containers/Category')))
const CategoryRegister = lazy(() => lazyRetry(() => import('containers/Category/register')))
const ViewHierarchy = lazy(() => lazyRetry(() => import('containers/Category/viewHierarchy')))

const route = [
  {
    name: i18n.t('category.title'),
    path: '/category',
    main: Category,
    exact: true,
    permissions: ['ROLE_CADASTRO_CATEGORIA_LEITURA']
  },
  {
    name: i18n.t('category.registerTitle'),
    path: '/category/register',
    main: CategoryRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_CATEGORIA_ESCRITA']
  },
  {
    name: i18n.t('general.hierarchyTitle'),
    path: '/category/hierarchy',
    main: ViewHierarchy,
    exact: true,
    permissions: ['ROLE_CADASTRO_CATEGORIA_LEITURA']
  },
  {
    name: i18n.t('category.editTitle'),
    path: '/category/edit/:id',
    main: CategoryRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_CATEGORIA_ESCRITA']
  }
]

export default route
