import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const ChangeExpirationDate = lazy(() => lazyRetry(() => import('containers/ChangeExpirationDate')))

const route = [
  {
    name: i18n.t('changeExpirationDate.title'),
    path: '/change-expiration-date',
    main: ChangeExpirationDate,
    exact: true,
    permissions: ['ROLE_GESTAO_DOCUMENTOS_ELETRONICOS_ALTERA_DTA_VENCIMENTO_NF']
  }
]

export default route
