import { lazy } from 'react'
import i18n from 'common/i18n'
import { lazyRetry } from '../help/utils'

const BusinessType = lazy(() => lazyRetry(() => import('containers/BusinessType')))
const BusinessTypeRegister = lazy(() => lazyRetry(() => import('containers/BusinessType/register')))

const route = [
  {
    name: i18n.t('businessType.title'),
    path: '/business-type',
    main: BusinessType,
    exact: true,
    permissions: ['ROLE_CADASTRO_TIPO_NEGOCIO_LEITURA']
  },
  {
    name: i18n.t('businessType.registerTitle'),
    path: '/business-type/register',
    main: BusinessTypeRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_TIPO_NEGOCIO_ESCRITA']
  },
  {
    name: i18n.t('businessType.editTitle'),
    path: '/business-type/edit/:id',
    main: BusinessTypeRegister,
    exact: true,
    permissions: ['ROLE_CADASTRO_TIPO_NEGOCIO_ESCRITA']
  }
]

export default route
